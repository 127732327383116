.order-card {
  margin-bottom: 32px;
  color: #676666;
  padding-top: 20px;
  .order-id-label {
    display: inline;
    background: #21aae3;
    color: white;
    padding: 5px 10px;
    position: absolute;
    left: -8px;
    top: 10px;
  }
  .order-id-label:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #21aae3;
    top: -8px;
    left: 0px;
    position: absolute;
  }
  .header-item {
    display: flex;
    .label {
      min-width: 91px;
    }
    .value {
      flex: 1;
    }
  }
}
.order-cancel-confirm {
  text-align: center;
}
