html, body {
    min-width: 1280px;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root, .content-body {
    height: 100%
}
html {
    font-size: 12px;
}
@media screen and (min-width: 320px) {
    html {
        font-size: calc(12px + 4 * ((100vw - 320px) / 961));
    }
}
@media screen and (min-width: 1281px) {
    html {
        font-size: 16px;
    }
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none !important;
}

.ant-card {
    border-radius: 4px;
    box-shadow: 0rem 0.21rem 0.42rem 0 rgba(0, 0, 0, 0.16);
}
.ant-tabs-tab {
    font-size: 1.14rem;
}
.ant-tabs-tab-active,
.ant-tabs-tab:active,
.ant-tabs-tab:hover {
    color: #23202C !important;
}
.ant-tabs-ink-bar {
    background: #23202C;
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item:active {
    background: #FF5858 !important;
    border-color: #FF5858 !important;
    outline: none !important;
}
.ant-pagination-item a {
    color: rgba(0, 0, 0, 0.65);
}
.ant-pagination-item-active a,
.ant-pagination-item:hover a,
.ant-pagination-item:active a {
    color: #FFFFFF !important;
}

.delete-button,
.delete-button:hover,
.delete-button:active {
    background: #FFFFFF !important;
    color: #F84B3D !important;
    border-color: #F84B3D !important;
    outline: none !important;
    font-size: 0.86rem;
}

.ant-skeleton,
.ant-alert-error {
    width: 90%;
    margin: auto;
}

.ant-modal-mask {
    background: rgba(2, 4, 41, 0.9);
}
.login-form {
  height: 100%; }
  .login-form a {
    color: #285be0; }
  .login-form .ant-checkbox-checked .ant-checkbox-inner {
    background: #285be0;
    border-color: #285be0; }
  .login-form .sidebar {
    height: 100%;
    background: url(/static/media/login_illustration.a2bc9025.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .login-form .form-button {
    display: block;
    background: #285be0;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    border: none;
    outline: none; }

.login-form-container {
  padding: 5rem 2rem 0 2rem;
  height: 100%;
  background: white;
  background-size: cover;
  background-repeat: no-repeat; }
  .login-form-container .content-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    height: 100%; }
  .login-form-container .logo {
    margin: auto;
    width: 13rem;
    display: block; }
  .login-form-container .tagline {
    text-align: center;
    margin-top: 0.7rem;
    font-weight: 300; }
  .login-form-container .content {
    padding-top: 3rem; }
    .login-form-container .content .heading {
      color: #072c4b;
      font-size: 32px;
      text-align: center;
      font-weight: 400;
      margin-bottom: 0; }
    .login-form-container .content .subheading {
      font-size: 16px;
      text-align: center; }
    .login-form-container .content .heading-separator {
      border-color: #072c4b;
      margin: auto;
      width: 45%;
      margin-bottom: 1.5rem; }
    .login-form-container .content .ant-form-item {
      width: 80%;
      margin: auto;
      margin-bottom: 1rem;
      font-size: 1rem; }
    .login-form-container .content .ant-input {
      border-color: #d9d9d9; }
    .login-form-container .content .flex-div .ant-form-item-children {
      display: -webkit-flex;
      display: flex;
      font-size: 1rem;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
    .login-form-container .content .flex-div .gap {
      -webkit-flex: 1 1;
              flex: 1 1; }
  .login-form-container .footer {
    position: -webkit-sticky;
    position: sticky;
    top: 100%;
    padding-bottom: 30px; }
    .login-form-container .footer .links {
      display: -webkit-flex;
      display: flex;
      margin-left: 30%;
      width: 50%; }
      .login-form-container .footer .links a {
        -webkit-flex: 1 1;
                flex: 1 1; }
    .login-form-container .footer .copyright-text {
      margin-left: 32%;
      width: 50%; }

.forgot-password-form {
  height: 100%; }
  .forgot-password-form a {
    color: #285be0; }
  .forgot-password-form .sidebar {
    height: 100%;
    background: url(/static/media/login_illustration.a2bc9025.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .forgot-password-form .sub-heading {
    font-size: 1.43rem;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem; }
  .forgot-password-form .form-button {
    display: inline-block;
    background: #285be0;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
    width: 50%;
    border: none;
    outline: none; }
  .forgot-password-form .anticon.anticon-left-circle {
    font-size: 2rem; }

.signup-form-container {
  height: 100%;
  padding-top: 30px;
  background: url(/static/media/onboarding_bg.3a6bf821.svg);
  background-position-y: top;
  background-size: contain;
  background-repeat: no-repeat; }
  .signup-form-container img.logo {
    display: block;
    margin: 30px auto;
    width: 250px; }
  .signup-form-container .signup-card {
    width: 700px;
    max-width: 100%;
    margin: auto;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0; }
    .signup-form-container .signup-card .ant-card-body {
      padding: 2.57rem; }
      .signup-form-container .signup-card .ant-card-body .title {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 0;
        color: #3a3543;
        font-weight: 500; }
      .signup-form-container .signup-card .ant-card-body .sub-title {
        text-align: center;
        font-size: 1.143rem;
        color: #000;
        font-weight: lighter; }
      .signup-form-container .signup-card .ant-card-body .signup-form {
        padding-top: 2.143rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .flex {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          -webkit-align-items: flex-start;
                  align-items: flex-start; }
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .ant-form-item,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .form-button,
          .signup-form-container .signup-card .ant-card-body .signup-form .flex .disclaimer {
            width: 48%;
            margin-bottom: 0.36rem; }
        .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item {
          display: block;
          margin-top: 0.71rem;
          margin-bottom: 1.07rem; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input {
            border: 2px solid #cdcdcc;
            border-radius: 4px;
            min-height: 40px; }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:hover,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:active,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus,
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus-within {
            border-color: #4d4d4d;
            box-shadow: 0 0 0 2px rgba(77, 77, 77, 0); }
          .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper {
            display: block; }
            .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper span {
              color: #4d4d4d;
              font-size: 1rem;
              font-weight: lighter; }
        .signup-form-container .signup-card .ant-card-body .signup-form .has-error input {
          border-color: #ff1c1c !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .label {
          font-size: 1rem;
          font-weight: lighter;
          color: #808080; }
        .signup-form-container .signup-card .ant-card-body .signup-form .checkbox-label {
          color: #868686;
          font-size: 1rem;
          font-weight: 400;
          font-style: italic;
          display: block; }
        .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer {
          font-weight: 500; }
          .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer a {
            color: #ff806f !important; }
        .signup-form-container .signup-card .ant-card-body .signup-form .form-button {
          font-size: 1.86rem;
          background: #00db6e;
          border-color: #00db6e;
          outline: none;
          padding: 16px 10%;
          text-transform: capitalize;
          height: 5.5rem;
          max-height: 100%;
          font-weight: 600;
          border-radius: 10px; }
      .signup-form-container .signup-card .ant-card-body .bottom-link {
        color: #868686;
        font-size: 0.86rem;
        font-weight: 500;
        text-align: center;
        margin: 1rem 0 0 0; }
        .signup-form-container .signup-card .ant-card-body .bottom-link a {
          color: #ff806f !important; }

.signup-password-popover-content h5 {
  color: #535353;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.1rem; }

.signup-password-popover-content p {
  color: #868686;
  font-size: 1rem;
  margin-bottom: 0.1rem; }
  .signup-password-popover-content p svg {
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom; }

.forgot-password-form {
  height: 100%; }
  .forgot-password-form .sub-heading {
    font-size: 1.43rem;
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem; }
  .forgot-password-form .form-button {
    display: inline-block;
    background: #285be0;
    color: #ffffff;
    font-size: 1rem;
    text-align: center;
    width: 50%;
    border: none;
    outline: none; }
  .forgot-password-form .anticon.anticon-left-circle {
    font-size: 2rem; }

.ant-layout {
  background: #F1F0F5; }
  .ant-layout .ant-layout-has-sider {
    height: 100%; }

.ant-card {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-color: #edf2f9;
  border-radius: 0.3rem; }

.navbar {
  padding: 0 1.5rem;
  z-index: 1001;
  background: -webkit-linear-gradient(right, #041421, #072c4b);
  background: linear-gradient(-90deg, #041421, #072c4b);
  font-weight: 600;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center; }
  .navbar .left-menu {
    -webkit-flex: 99999 1;
            flex: 99999 1;
    padding-left: 10px; }
  .navbar .right-menu {
    -webkit-flex: 0 0;
            flex: 0 0; }
    .navbar .right-menu .ant-menu-item:last-child {
      padding-right: 0; }
  .navbar .navbar-ham {
    display: none;
    color: white;
    font-size: 1.71rem; }
  .navbar .logo {
    -webkit-flex: 0 0;
            flex: 0 0; }
    .navbar .logo img {
      max-width: 3rem;
      padding-bottom: 0.25rem; }
  .navbar .ant-menu-dark {
    background: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .navbar .ant-menu-dark .ant-menu-item {
      padding: 0; }
      .navbar .ant-menu-dark .ant-menu-item a {
        font-size: 16px;
        letter-spacing: 0.3px; }
      .navbar .ant-menu-dark .ant-menu-item svg * {
        stroke: transparent;
        fill: rgba(255, 255, 255, 0.65); }
      .navbar .ant-menu-dark .ant-menu-item .user-name {
        text-transform: capitalize; }
      .navbar .ant-menu-dark .ant-menu-item .user-status-tag {
        margin-left: 0.25rem; }
      .navbar .ant-menu-dark .ant-menu-item .anticon {
        font-size: 1.5rem;
        padding-top: 1rem;
        margin-right: 0; }
        .navbar .ant-menu-dark .ant-menu-item .anticon svg {
          position: relative;
          top: 2px; }
      .navbar .ant-menu-dark .ant-menu-item .ant-avatar {
        background-color: #fc4747;
        border: 2px solid white;
        box-sizing: content-box;
        text-transform: capitalize;
        font-size: 1.57rem; }
      .navbar .ant-menu-dark .ant-menu-item .avatar-details {
        display: inline-block;
        margin: 0 0.75rem;
        font-size: 0.93rem;
        line-height: 20px;
        vertical-align: middle;
        text-transform: capitalize;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0; }
        .navbar .ant-menu-dark .ant-menu-item .avatar-details .domain-name {
          font-size: 0.78rem; }
    .navbar .ant-menu-dark .ant-menu-item {
      padding: 15px 12px; }
    .navbar .ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu-item-active,
    .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-active,
    .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
    .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active {
      background: transparent;
      color: white; }
      .navbar .ant-menu-dark .ant-menu-item-selected svg *,
      .navbar .ant-menu-dark .ant-menu-item-active svg *,
      .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-selected svg *,
      .navbar .ant-menu-dark .ant-menu.ant-menu-dark .ant-menu-item-active svg *,
      .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected svg *,
      .navbar .ant-menu-dark .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active svg * {
        fill: white; }

.navbar.geminii {
  background: -webkit-linear-gradient(45deg, #f7931e, #fb5848);
  background: linear-gradient(45deg, #f7931e, #fb5848); }
  .navbar.geminii .ant-menu {
    background: transparent;
    border: none; }

.navbar.autoresponder {
  background: -webkit-linear-gradient(left, #1655c0, #0e4081);
  background: linear-gradient(90deg, #1655c0, #0e4081); }
  .navbar.autoresponder .ant-menu {
    background: transparent;
    border: none; }

.app-switcher-menu.email {
  background: #051e33;
  padding: 0; }
  .app-switcher-menu.email li {
    height: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .app-switcher-menu.email .ant-dropdown-menu-item-active {
    background: #25323d; }

.app-switcher-menu.autoresponder {
  background: #1552ba;
  padding: 0; }
  .app-switcher-menu.autoresponder li {
    height: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .app-switcher-menu.autoresponder .ant-dropdown-menu-item-active {
    background: #1649a1; }

.app-switcher-menu.geminii {
  background: #f78f22;
  padding: 0; }
  .app-switcher-menu.geminii li {
    height: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .app-switcher-menu.geminii .ant-dropdown-menu-item-active {
    background: #f9a554; }

.navbar-user-dropdown {
  min-width: 205px;
  padding: 2.2px; }
  .navbar-user-dropdown p {
    margin-bottom: 0; }
  .navbar-user-dropdown .current-account-details {
    font-size: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-user-dropdown .current-account-details .account-name {
      margin-right: 0.71rem;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-flex: 999 1;
              flex: 999 1; }
    .navbar-user-dropdown .current-account-details .ant-tag {
      border-radius: 9px;
      margin-right: 0; }
  .navbar-user-dropdown .switch-account-label {
    font-size: 0.85rem;
    margin-top: 0.71rem; }
  .navbar-user-dropdown .ant-dropdown-menu-item {
    font-size: 1rem;
    padding: 15px 20px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .navbar-user-dropdown .ant-dropdown-menu-item .anticon {
      margin-left: 8px;
      display: none; }
  .navbar-user-dropdown .ant-dropdown-menu-item:hover {
    color: #00a154 !important;
    background: #f7fbff; }
    .navbar-user-dropdown .ant-dropdown-menu-item:hover .anticon {
      display: inline-block; }

@media (max-width: 768px) {
  .header .ant-menu {
    display: none; }
  .header.ant-layout-header .navbar-ham {
    display: block;
    float: right; } }

.user-settings {
  padding: 1.5rem 3rem; }
  .user-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .user-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .user-settings .heading .add-user-btn {
      background: #FF5858;
      border-color: #FF5858;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 3px;
      height: 2.5rem; }
      .user-settings .heading .add-user-btn .anticon + span {
        margin-left: 0.25rem; }
    .user-settings .heading .add-user-btn:hover,
    .user-settings .heading .add-user-btn:active,
    .user-settings .heading .add-user-btn:focus,
    .user-settings .heading .add-user-btn:focus-within {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }
  .user-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .user-settings .disclaimer {
    font-size: 1.14rem;
    color: #979797; }
  .user-settings .ant-table {
    margin-top: 1.5rem; }
    .user-settings .ant-table .user-name {
      text-transform: capitalize;
      margin-bottom: 0; }
    .user-settings .ant-table .role-tag {
      border-radius: 9px;
      font-size: 0.86rem;
      font-weight: 500;
      padding: 0 1rem; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn {
      height: 38px;
      width: 38px;
      font-size: 1rem;
      padding: 8px;
      margin-right: 0.714rem;
      font-weight: 500; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn:hover svg * {
      fill: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn:hover {
      background: #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn:hover {
      background: #F84B3D; }

.user-modal .ant-modal-content {
  border-radius: 3px;
  border: 1px solid #707070;
  box-shadow: none;
  padding: 2rem; }
  .user-modal .ant-modal-content .ant-modal-close {
    display: none; }
  .user-modal .ant-modal-content .ant-modal-header {
    border: none; }
    .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom {
      font-size: 1.78rem;
      color: #020429;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg {
        width: 1.71rem;
        height: 1.71rem;
        cursor: pointer; }
        .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg * {
          color: rgba(0, 0, 0, 0.65); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .label {
    font-size: 1.14rem;
    color: #464646;
    font-weight: 400;
    margin-bottom: 0.5rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select {
    width: 100%; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select-dropdown-menu-item {
    border-bottom: 2px solid; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button {
    width: 100%;
    background: #36C626;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    height: 2.86rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:hover,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:active,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus-within {
    background: rgba(54, 198, 38, 0.9); }

.option-span {
  margin-bottom: 0; }

.role-permissions-data .title {
  font-size: 0.71rem;
  color: #595959;
  font-weight: 500;
  margin-bottom: 0.5rem; }

.role-permissions-data p {
  font-size: 0.71rem;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .role-permissions-data p svg {
    width: 0.93rem;
    height: 0.93rem;
    margin-right: 0.5rem; }

.app-footer {
  width: 1200px;
  margin: auto;
  max-width: 100%;
  padding: 1.6rem 0; }
  .app-footer ul {
    list-style: none;
    padding: 0; }
    .app-footer ul li {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.57rem; }
      .app-footer ul li a {
        color: #979797 !important;
        font-weight: 500; }
      .app-footer ul li img {
        width: 8rem; }

.switch-account-modal .switch-account-text .title {
  color: #5d5c5c; }

.switch-account-modal .switch-account-text button {
  display: block;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  /* padding: initial; */
  font-size: 1rem;
  border: 2px solid #979797;
  margin-bottom: 1rem; }
  .switch-account-modal .switch-account-text button span .ant-tag {
    margin-left: 15px; }
  .switch-account-modal .switch-account-text button svg {
    width: 1rem;
    height: 1rem; }

.switch-account-modal .switch-account-text button:hover {
  font-weight: 500;
  background: #e5ddff;
  color: #5d5c5c; }
  .switch-account-modal .switch-account-text button:hover svg {
    width: 1.3rem;
    height: 1.3rem; }

.campaign-steps .ant-modal-content .ant-modal-close {
  top: -40px;
  right: -60px; }
  .campaign-steps .ant-modal-content .ant-modal-close .ant-modal-close-x {
    font-size: 28px; }
    .campaign-steps .ant-modal-content .ant-modal-close .ant-modal-close-x .anticon {
      color: white; }

.campaign-steps .ant-modal-content .ant-modal-body {
  height: auto; }
  .campaign-steps .ant-modal-content .ant-modal-body .main-steps-container .steps-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .campaign-steps .ant-modal-content .ant-modal-body .main-steps-container .steps-header .step-info {
      width: 50%; }
      .campaign-steps .ant-modal-content .ant-modal-body .main-steps-container .steps-header .step-info h1 {
        font-size: 30px;
        color: #242425;
        font-weight: 600;
        margin-bottom: 0px; }
        .campaign-steps .ant-modal-content .ant-modal-body .main-steps-container .steps-header .step-info h1 .anticon {
          margin-right: 10px; }
      .campaign-steps .ant-modal-content .ant-modal-body .main-steps-container .steps-header .step-info p {
        font-size: 18px;
        color: #242425;
        font-weight: 400;
        margin-bottom: 0px; }
  .campaign-steps .ant-modal-content .ant-modal-body .main-steps-container .step-name {
    margin-top: 30px; }
    .campaign-steps .ant-modal-content .ant-modal-body .main-steps-container .step-name .ant-btn-link {
      font-size: 15px !important;
      font-weight: 600;
      color: #1654be;
      border: none;
      padding: 0px;
      box-shadow: unset; }
    .campaign-steps .ant-modal-content .ant-modal-body .main-steps-container .step-name p {
      font-size: 13px !important;
      color: #242425 !important;
      font-weight: 400 !important;
      margin-bottom: 0px; }

.no-table-data {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 2.11rem 0; }
  .no-table-data svg {
    width: 21.51rem;
    height: 16.44rem;
    max-width: 100%;
    margin-right: 1.36rem; }
  .no-table-data .text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 24.21rem; }
    .no-table-data .text .heading {
      font-size: 1.43rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0.36rem; }
    .no-table-data .text .content {
      text-align: left;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0;
      font-size: 1.143rem;
      font-weight: 400; }

.snapshot {
  background: #F1F0F5; }
  .snapshot .heading {
    font-size: 1.14rem;
    color: #5F5F5F; }
  .snapshot .no-data {
    margin-top: 4rem;
    padding: 0;
    background: url(/static/media/backgroundPLaceholder.d708de91.svg);
    background-position-y: 4.3rem;
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    min-height: 18.357rem;
    border-radius: 4px;
    margin-bottom: 2.88rem; }
    .snapshot .no-data .ant-card-body {
      padding: 3.12rem 4rem; }
      .snapshot .no-data .ant-card-body .heading {
        font-size: 1.786rem;
        font-weight: 500;
        color: #242425;
        margin-bottom: 0.236rem; }
      .snapshot .no-data .ant-card-body .text {
        color: rgba(0, 0, 0, 0.45);
        font-size: 1.143rem;
        width: 60%;
        margin-bottom: 1.26rem; }
      .snapshot .no-data .ant-card-body .ant-btn {
        background: #FF2F2F;
        color: #fff !important;
        text-decoration: none !important;
        font-size: 1rem;
        font-weight: 500;
        outline: none !important;
        border-color: #FF2F2F !important;
        height: 2.786rem;
        border-radius: 2px; }
      .snapshot .no-data .ant-card-body .ant-btn:hover,
      .snapshot .no-data .ant-card-body .ant-btn:active,
      .snapshot .no-data .ant-card-body .ant-btn:focus,
      .snapshot .no-data .ant-card-body .ant-btn:focus-within {
        background: rgba(255, 47, 47, 0.85) !important; }

.overall-stats .stat-card .ant-card-body {
  padding: 10px 24px; }
  .overall-stats .stat-card .ant-card-body p {
    margin: 0; }

.last-30-day-stats {
  padding-top: 3rem; }
  .last-30-day-stats .ant-card .ant-card-body {
    padding: 0rem;
    padding-top: 0.5rem; }
    .last-30-day-stats .ant-card .ant-card-body .ant-tabs-bar.ant-tabs-top-bar {
      padding-left: 3rem;
      padding-right: 3rem; }
    .last-30-day-stats .ant-card .ant-card-body .g2-tooltip-list-item::before {
      content: "\2022";
      color: red;
      font-weight: 600;
      font-size: 1.5rem;
      border-radius: 50%;
      margin-right: 0.25rem; }
    .last-30-day-stats .ant-card .ant-card-body .placeholder {
      text-align: center;
      padding: 2rem; }
      .last-30-day-stats .ant-card .ant-card-body .placeholder p {
        font-size: 2rem;
        font-weight: 700; }

.top-products {
  margin: 3rem 0 2rem 0; }
  .top-products table thead th {
    background: #16273b !important;
    color: white; }
  .top-products table tbody {
    background: white; }
  .top-products .product-details {
    display: -webkit-flex;
    display: flex; }
    .top-products .product-details img {
      width: 120px;
      height: 120px;
      border: 1px solid black; }
    .top-products .product-details .product-info {
      margin-left: 12px; }
      .top-products .product-details .product-info h4 {
        color: #23202c;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 8px; }
      .top-products .product-details .product-info p {
        color: #000000a6;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 8px; }

.top-products {
  margin: 3rem 0 2rem 0; }
  .top-products .customer-details p {
    margin: 0; }

.main-heading-bar {
  padding: 3rem 0 2rem 0;
  color: #000; }
  .main-heading-bar .title {
    font-size: 1.78rem;
    font-weight: 500;
    margin-bottom: 0; }
  .main-heading-bar .subtitle {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0; }
  .main-heading-bar .page-cta {
    margin-top: 6px;
    width: 100%; }
  .main-heading-bar .actions {
    text-align: right; }
    .main-heading-bar .actions .ant-btn {
      background: #ff5858;
      color: #fff;
      outline: none !important;
      border-color: #ff5858 !important;
      border-radius: 2px;
      font-size: 1rem; }

.library .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 1.5rem;
  font-size: 1.28rem;
  font-weight: 300; }

.library .ant-card-cover {
  height: 10rem;
  overflow: hidden; }

.library .selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.3); }

.library .media-meta p {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.75rem; }

.library .media-meta .ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem; }

.library .media-meta .ant-checkbox-inner:after {
  width: 0.48rem;
  height: 1.07rem;
  left: 4.571429px;
  top: 9.142857px; }

.library .media-meta .ant-checkbox-wrapper {
  position: absolute;
  bottom: -1px;
  right: 0; }

.library-modal {
  width: 38rem !important; }
  .library-modal .ant-modal-content {
    padding: 3.14rem 4.14rem; }
    .library-modal .ant-modal-content .ant-modal-close {
      right: 3.14rem;
      top: 2.34rem;
      color: black; }
      .library-modal .ant-modal-content .ant-modal-close .anticon {
        font-size: 20px; }
    .library-modal .ant-modal-content .ant-modal-header {
      padding: 0;
      border: 0;
      margin-bottom: 2.14rem; }
      .library-modal .ant-modal-content .ant-modal-header .ant-modal-title {
        font-size: 1.78rem;
        font-weight: 500; }
    .library-modal .ant-modal-content .ant-modal-body {
      padding: 0; }
    .library-modal .ant-modal-content .choose-image-from-library {
      max-height: 30rem;
      overflow: scroll; }

.profile-settings .profile-form {
  color: #000; }
  .profile-settings .profile-form .column {
    padding: 1.5rem;
    padding-bottom: 0; }
  .profile-settings .profile-form .form-labels {
    margin: 0;
    font-size: 1.14rem;
    color: #151515; }
  .profile-settings .profile-form .button-container {
    text-align: right; }
    .profile-settings .profile-form .button-container .ant-btn-primary {
      background: #92c15f;
      border-color: #92c15f; }
    .profile-settings .profile-form .button-container .ant-btn-primary:hover, .profile-settings .profile-form .button-container .ant-btn-primary:focus {
      background: #69bd42;
      border-color: #69bd42; }
  @media (max-width: 576px) {
    .profile-settings .profile-form .button-container {
      text-align: center; } }
  .profile-settings .profile-form .ant-divider-horizontal {
    margin: 1rem 0; }

.manage-accounts .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
  font-size: 1.28rem;
  font-weight: 300; }

.manage-accounts .ant-card {
  border-radius: 2px;
  padding: 0.57rem; }
  .manage-accounts .ant-card .ant-card-body {
    padding: 0;
    padding-bottom: 0.5rem; }
    .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn {
      height: 2.5rem;
      margin-right: 0.714rem;
      padding: 0 0.714rem;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn span {
        display: none; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn svg {
        width: 1.14rem;
        height: 1.14rem; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .icon {
        width: 1.14rem;
        height: 1.14rem;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .text {
        display: inline-block;
        height: 1.14rem;
        margin: 0;
        width: 0;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover {
      background: #23202C;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover {
      background: #F84B3D;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn {
      border: 1px solid #FF8200;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn svg * {
        stroke: #FF8200;
        fill: #FF8200; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover {
      background: #FF8200;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover svg * {
        fill: white; }

.manage-accounts .ant-table th:first-of-type, .manage-accounts .ant-table td:first-of-type {
  padding-left: 3rem; }

.manage-accounts .ant-table th:last-of-type, .manage-accounts .ant-table td:last-of-type {
  padding-right: 3rem; }

.edit-views-modal .ant-modal-header {
  padding: 0;
  border-bottom: 0; }
  .edit-views-modal .ant-modal-header .ant-modal-title {
    text-align: center;
    font-size: 1.42rem;
    padding-top: 3.5rem; }

.edit-views-modal .ant-modal-body {
  padding: 1.71rem 5.3rem;
  text-align: center;
  font-size: 1.14rem; }
  .edit-views-modal .ant-modal-body .edit-view-modal-save-btn {
    margin-top: 1rem;
    background: #36C626;
    border-color: #36C626;
    color: white;
    border-radius: 3px; }

.manage-accounts .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0;
  font-size: 1.28rem;
  font-weight: 300; }

.manage-accounts .ant-card {
  border-radius: 2px;
  padding: 0.57rem; }
  .manage-accounts .ant-card .ant-card-body {
    padding: 0;
    padding-bottom: 0.5rem; }
    .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn {
      height: 2.5rem;
      margin-right: 0.714rem;
      padding: 0 0.714rem;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn span {
        display: none; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn svg {
        width: 1.14rem;
        height: 1.14rem; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .icon {
        width: 1.14rem;
        height: 1.14rem;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
      .manage-accounts .ant-card .ant-card-body .actions-div .ant-btn .text {
        display: inline-block;
        height: 1.14rem;
        margin: 0;
        width: 0;
        overflow: hidden;
        -webkit-transition: width 0.1s !important;
        transition: width 0.1s !important; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover {
      background: #23202C;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .edit-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover {
      background: #F84B3D;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .delete-btn:hover svg * {
        fill: white; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn {
      border: 1px solid #FF8200;
      background: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn svg * {
        stroke: #FF8200;
        fill: #FF8200; }
    .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover {
      background: #FF8200;
      color: white; }
      .manage-accounts .ant-card .ant-card-body .actions-div .pause-btn:hover svg * {
        fill: white; }

.manage-accounts .ant-table th:first-of-type, .manage-accounts .ant-table td:first-of-type {
  padding-left: 3rem; }

.manage-accounts .ant-table th:last-of-type, .manage-accounts .ant-table td:last-of-type {
  padding-right: 3rem; }

.edit-views-modal .ant-modal-header {
  padding: 0;
  border-bottom: 0; }
  .edit-views-modal .ant-modal-header .ant-modal-title {
    text-align: center;
    font-size: 1.42rem;
    padding-top: 3.5rem; }

.edit-views-modal .ant-modal-body {
  padding: 1.71rem 5.3rem;
  text-align: center;
  font-size: 1.14rem; }
  .edit-views-modal .ant-modal-body .edit-view-modal-save-btn {
    margin-top: 1rem;
    background: #36C626;
    border-color: #36C626;
    color: white;
    border-radius: 3px; }

.bonus-page table {
  width: 100%; }
  .bonus-page table td {
    vertical-align: top;
    padding: 8px 0; }
  .bonus-page table th:nth-child(2), .bonus-page table td:nth-child(2) {
    width: calc(100% - 400px);
    padding-left: 15px;
    padding-right: 15px; }

.settings .ant-switch-checked {
  background-color: #37c627; }

.settings .ant-tabs {
  background: white;
  border: 1px solid #dedede;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem 1rem; }
  .settings .ant-tabs .ant-tabs-tab {
    font-size: 1.14rem;
    color: #1d1d1d !important;
    font-weight: 300;
    text-align: left;
    padding-right: 5rem;
    padding-bottom: 1.25rem; }
  .settings .ant-tabs .ant-tabs-tab-active {
    color: #100e1a !important;
    font-weight: 500; }
  .settings .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    width: 5px;
    background: #100e1a; }

.agencySiteModal .ant-modal-confirm-btns {
  width: 100%; }

.settings .ant-switch-checked {
  background-color: #37c627; }

.settings .ant-tabs {
  background: white;
  border: 1px solid #dedede;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem 1rem; }
  .settings .ant-tabs .ant-tabs-tab {
    font-size: 1.14rem;
    color: #1d1d1d !important;
    font-weight: 300;
    text-align: left;
    padding-right: 5rem;
    padding-bottom: 1.25rem; }
  .settings .ant-tabs .ant-tabs-tab-active {
    color: #100e1a !important;
    font-weight: 500; }
  .settings .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    width: 5px;
    background: #100e1a; }

.onboarding-container {
  min-height: 100vh;
  background: url(/static/media/onboarding_bg.3a6bf821.svg);
  background-size: 100% auto;
  background-repeat: no-repeat; }
  .onboarding-container a {
    color: white; }
  .onboarding-container .onboarding {
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 4.143rem; }
    .onboarding-container .onboarding .logo {
      width: 260px;
      margin-bottom: 1.643rem; }
    .onboarding-container .onboarding .progress-bar {
      position: relative;
      width: 22.21rem;
      height: 1.43rem;
      border-radius: 10px;
      background: #ededed;
      margin-bottom: 4.143rem; }
    .onboarding-container .onboarding .progress-bar::after {
      content: "";
      position: absolute;
      left: 0;
      background: #36c626;
      border-radius: 10px;
      width: 8rem;
      height: 1.43rem;
      z-index: 9; }
    .onboarding-container .onboarding .title {
      color: #020429;
      font-size: 1.43rem;
      font-weight: 500;
      margin-bottom: 0.43rem; }
    .onboarding-container .onboarding .sub-title {
      font-size: 1.286rem;
      color: #8c8c8c;
      font-weight: 400;
      margin-bottom: 2rem; }

.ant-modal.onboarding-modal .ant-modal-content {
  border-radius: 3px; }
  .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body {
    padding: 3.36rem 3.786rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .heading {
      font-size: 1.714rem;
      font-weight: 500;
      margin-bottom: 12px;
      color: #285be0; }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn {
      background: #36c626;
      color: #fff;
      outline: none;
      font-size: 1rem;
      font-weight: 600;
      width: 20.71rem;
      height: 2.86rem;
      outline: none !important; }
      .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn .anticon svg {
        width: 0.968rem;
        height: 0.82rem;
        margin-bottom: 0;
        vertical-align: text-top; }
        .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn .anticon svg * {
          fill: #fff; }
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:hover,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:active,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:focus,
    .ant-modal.onboarding-modal .ant-modal-content .ant-modal-body .ant-btn:focus-within {
      border-color: #36c626; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body svg {
  width: 10rem;
  height: 10rem;
  -webkit-margin-after: 1.71rem;
          margin-block-end: 1.71rem; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body .heading {
  color: #020429;
  font-size: 1.786rem;
  font-weight: 600; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para {
  font-size: 1.14rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #8c8c8c; }
  .ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para img {
    width: 1.286rem;
    vertical-align: text-bottom; }
  .ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para .name {
    text-transform: capitalize; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body p.para-3 {
  color: #464646; }

.ant-modal.onboarding-modal.welcome-modal .ant-modal-content .ant-modal-body .below-button {
  font-size: 0.86rem;
  color: #8c8c8c;
  font-weight: 400;
  margin-top: 0.286rem; }

.ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body,
.ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body {
  padding: 3.214rem 3.357rem; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body svg,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body svg {
    margin-bottom: 2.21rem; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body .heading,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body .heading {
    text-align: center;
    color: #285be0;
    font-size: 24px;
    font-weight: bold; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body .disclaimer,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body .disclaimer {
    font-size: 1rem;
    font-weight: 400;
    color: #4b4b4b;
    text-align: center; }
  .ant-modal.onboarding-modal.onboarding-success-modal .ant-modal-content .ant-modal-body .below-disclaimer,
  .ant-modal.onboarding-modal.onboarding-failure-modal .ant-modal-content .ant-modal-body .below-disclaimer {
    margin-top: 2.07rem; }

.onboarding-form h1 {
  font-size: 24px;
  font-weight: 600;
  color: #285be0;
  margin-bottom: 0; }

.onboarding-form p {
  font-size: 16px;
  color: #4b4b4b; }

.onboarding-form .ant-form-item {
  margin-bottom: 1.93rem; }
  .onboarding-form .ant-form-item .input-label {
    font-size: 1.143rem;
    margin-bottom: 0.5rem;
    color: #151515;
    font-weight: 400; }
  .onboarding-form .ant-form-item .ant-input {
    border: 1px solid #979797;
    width: 100%;
    height: 2.286rem;
    font-size: 1rem;
    font-weight: 400; }
  .onboarding-form .ant-form-item .ant-btn {
    width: 100%;
    height: 2.857rem;
    border: none !important;
    background: #36c626;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 2rem; }
    .onboarding-form .ant-form-item .ant-btn svg {
      width: 0.97rem;
      height: 0.82rem;
      vertical-align: text-top; }
      .onboarding-form .ant-form-item .ant-btn svg * {
        fill: #fff; }

.onboarding-form .ant-form-item:last-of-type {
  margin-bottom: 0; }

.onboarding-tooltip .ant-tooltip-arrow {
  top: 50%;
  margin-top: -10px;
  left: 0px;
  border-width: 10px 8.16px 10px 0;
  border-right-color: rgba(0, 0, 0, 0.75); }

.onboarding-tooltip .ant-tooltip-inner {
  padding: 0.643rem 1.286rem;
  font-size: 0.857rem;
  background: #3c3c3c;
  color: #ffffff;
  width: 15.642rem; }

.onboarding-footer {
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 10px; }
  .onboarding-footer div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 11.786rem; }
    .onboarding-footer div a {
      color: rgba(0, 0, 0, 0.45) !important;
      text-decoration: none !important;
      font-size: 1rem;
      font-weight: 400; }
  .onboarding-footer .copyright-text {
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.857rem;
    font-weight: 400; }

.user-profile-form {
  width: 500px; }
  .user-profile-form h3 {
    font-size: 24px;
    font-weight: 600;
    color: #285be0;
    margin-bottom: 0;
    text-align: center; }
  .user-profile-form p {
    font-size: 16px;
    color: #4b4b4b;
    text-align: center; }
  .user-profile-form .ant-form-item {
    margin-bottom: 1rem; }
    .user-profile-form .ant-form-item .label {
      font-size: 1rem;
      color: #595959; }
    .user-profile-form .ant-form-item .ant-input,
    .user-profile-form .ant-form-item .ant-select-selection {
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      height: 2.5rem;
      font-size: 1rem; }
    .user-profile-form .ant-form-item .ant-select-selection__rendered {
      height: 2.5rem;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
    .user-profile-form .ant-form-item .ant-btn-default {
      border-radius: 3;
      background: #36c626;
      color: #fff;
      height: 2.5rem;
      font-size: 1rem;
      font-weight: 600;
      padding: 0 1.71rem;
      margin: 0;
      border: none !important;
      outline: none !important; }
      .user-profile-form .ant-form-item .ant-btn-default svg {
        width: 1rem;
        height: 1rem; }
        .user-profile-form .ant-form-item .ant-btn-default svg * {
          fill: #fff;
          stroke-width: 0; }

.settings .ant-switch-checked {
  background-color: #37c627; }

.settings .ant-tabs {
  background: white;
  border: 1px solid #dedede;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem 1rem; }
  .settings .ant-tabs .ant-tabs-tab {
    font-size: 1.14rem;
    color: #1d1d1d !important;
    font-weight: 300;
    text-align: left;
    padding-right: 5rem;
    padding-bottom: 1.25rem; }
  .settings .ant-tabs .ant-tabs-tab-active {
    color: #100e1a !important;
    font-weight: 500; }
  .settings .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    width: 5px;
    background: #100e1a; }

.account-settings {
  padding: 1.5rem 3rem; }
  .account-settings p {
    margin: 0; }
  .account-settings .color-picker-container {
    position: absolute;
    bottom: 100%; }
    .account-settings .color-picker-container .chrome-picker {
      position: absolute;
      bottom: -100%; }
  .account-settings label {
    color: #151515;
    font-size: 1.14rem;
    margin-bottom: 0.5rem;
    font-weight: 400; }
  .account-settings .upload-actions {
    margin-top: 0.71rem;
    display: -webkit-flex;
    display: flex; }
    .account-settings .upload-actions .upload-action {
      width: 7.14rem;
      height: 7.14rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer; }
      .account-settings .upload-actions .upload-action .anticon {
        font-size: 2.25rem; }
  .account-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .account-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .account-settings .heading .ant-btn {
      background: #36c626;
      border-radius: 4px;
      border-color: #36c626 !important;
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      min-height: 2.5rem; }
  .account-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .account-settings .input-container {
    margin-bottom: 1rem; }
    .account-settings .input-container .label {
      color: #151515;
      font-size: 1.14rem;
      margin-bottom: 0.5rem;
      font-weight: 400; }
    .account-settings .input-container .ant-input {
      border: 1px solid #979797;
      border-radius: 4px;
      font-size: 1rem;
      padding: 1rem; }
    .account-settings .input-container .ant-input-disabled {
      background: #f1f0f5; }
    .account-settings .input-container .ant-select-selection:hover {
      border: 1px solid #979797; }
    .account-settings .input-container .switch-text {
      font-size: 1rem;
      font-weight: 400;
      color: #8c8c8c; }
      .account-settings .input-container .switch-text .ant-switch {
        vertical-align: baseline;
        margin-left: 0.5rem; }
      .account-settings .input-container .switch-text .ant-switch-checked {
        background: #36c626; }
  .account-settings .col-1 .input-container {
    margin-bottom: 2rem; }
  .account-settings .domain_name_container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1rem; }
    .account-settings .domain_name_container .ant-input {
      -webkit-flex: 0.9 1;
              flex: 0.9 1; }
    .account-settings .domain_name_container .anticon {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      cursor: pointer; }
      .account-settings .domain_name_container .anticon svg {
        width: 1.8rem;
        height: 1.8rem; }
  .account-settings .add-domain-btn {
    background: #ff5858;
    border-color: #ff5858;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 7px;
    height: 2.5rem; }
    .account-settings .add-domain-btn .anticon + span {
      margin-left: 0.25rem; }
  .account-settings .add-domain-btn:hover,
  .account-settings .add-domain-btn:active,
  .account-settings .add-domain-btn:focus,
  .account-settings .add-domain-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.whitelabel-instructions p {
  margin-bottom: 0; }

.user-settings {
  padding: 1.5rem 3rem; }
  .user-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .user-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .user-settings .heading .add-user-btn {
      background: #FF5858;
      border-color: #FF5858;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 3px;
      height: 2.5rem; }
      .user-settings .heading .add-user-btn .anticon + span {
        margin-left: 0.25rem; }
    .user-settings .heading .add-user-btn:hover,
    .user-settings .heading .add-user-btn:active,
    .user-settings .heading .add-user-btn:focus,
    .user-settings .heading .add-user-btn:focus-within {
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }
  .user-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .user-settings .disclaimer {
    font-size: 1.14rem;
    color: #979797; }
  .user-settings .ant-table {
    margin-top: 1.5rem; }
    .user-settings .ant-table .user-name {
      text-transform: capitalize;
      margin-bottom: 0; }
    .user-settings .ant-table .role-tag {
      border-radius: 9px;
      font-size: 0.86rem;
      font-weight: 500;
      padding: 0 1rem; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn {
      height: 38px;
      width: 38px;
      font-size: 1rem;
      padding: 8px;
      margin-right: 0.714rem;
      font-weight: 500; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn:hover svg * {
      fill: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn {
      border: 1px solid #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .edit-btn:hover {
      background: #23202C; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn {
      border: 1px solid #F84B3D;
      background: white; }
    .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .delete-btn:hover {
      background: #F84B3D; }

.user-modal .ant-modal-content {
  border-radius: 3px;
  border: 1px solid #707070;
  box-shadow: none;
  padding: 2rem; }
  .user-modal .ant-modal-content .ant-modal-close {
    display: none; }
  .user-modal .ant-modal-content .ant-modal-header {
    border: none; }
    .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom {
      font-size: 1.78rem;
      color: #020429;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg {
        width: 1.71rem;
        height: 1.71rem;
        cursor: pointer; }
        .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg * {
          color: rgba(0, 0, 0, 0.65); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .label {
    font-size: 1.14rem;
    color: #464646;
    font-weight: 400;
    margin-bottom: 0.5rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select {
    width: 100%; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select-dropdown-menu-item {
    border-bottom: 2px solid; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button {
    width: 100%;
    background: #36C626;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    height: 2.86rem; }
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:hover,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:active,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus,
  .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus-within {
    background: rgba(54, 198, 38, 0.9); }

.option-span {
  margin-bottom: 0; }

.role-permissions-data .title {
  font-size: 0.71rem;
  color: #595959;
  font-weight: 500;
  margin-bottom: 0.5rem; }

.role-permissions-data p {
  font-size: 0.71rem;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .role-permissions-data p svg {
    width: 0.93rem;
    height: 0.93rem;
    margin-right: 0.5rem; }

.product-card .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .product-card .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .product-card .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.product-card h4 {
  font-size: 24px;
  color: #fa5748;
  margin-bottom: 8px; }

.product-card .form-item-group {
  margin-bottom: 15px; }
  .product-card .form-item-group label {
    display: block;
    text-align: left; }

.product-card .form-item-group.has-error label {
  color: red; }

.product-card .form-item-group.has-error .error-label {
  color: red; }

.product-card .form-item-group.has-error .ant-input {
  border-color: red; }

.product-card .add-addon-link {
  display: block;
  text-align: center;
  margin: auto;
  color: #fa5748;
  font-weight: 500;
  font-size: 18px; }

.product-card .addon {
  display: -webkit-flex;
  display: flex; }
  .product-card .addon * {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0 5px; }

.product-card .ql-editor {
  min-height: 400px; }

.product-card .ant-form-item-label {
  line-height: 27px;
  font-size: 16px;
  color: black;
  margin-bottom: 8px; }

.product-card .ant-input {
  background: #efefef;
  border: 1px solid transparent;
  border-radius: 2px; }

.product-card .footer-button {
  width: 100%;
  background: #1bca00;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  margin-top: 20px; }

.product-card .ant-select-selection {
  background-color: #efefef;
  border-radius: 2px;
  border: none; }

.product-card .main-product-container .product-section {
  margin-top: 16px;
  font-size: 16px;
  color: black;
  margin-bottom: 8px !important;
  text-align: left; }

.product-container .category-tag {
  background: #e3fcff;
  padding: 8px 12px; }

.product-container .product-list .product-details {
  display: -webkit-flex;
  display: flex; }
  .product-container .product-list .product-details img {
    width: 120px;
    border: 1px solid black; }
  .product-container .product-list .product-details .product-info {
    margin-left: 12px; }
    .product-container .product-list .product-details .product-info h4 {
      color: #23202c;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 8px; }
    .product-container .product-list .product-details .product-info p {
      color: #000000a6;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 8px; }

.product-container .product-list .product-description {
  background: #f2f2f2;
  padding: 10px; }
  .product-container .product-list .product-description p {
    margin-bottom: 0px;
    color: #4b4b4b;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px; }

.product-container .product-list .product-cost {
  margin-bottom: 0px;
  color: #23202c;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px; }

.product-container .product-list .product-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: black;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center; }
  .product-container .product-list .product-actions .ant-switch-checked {
    background-color: #36c626; }
  .product-container .product-list .product-actions .anticon {
    font-size: 36px;
    color: #5c5c5c;
    margin-left: 5px; }
  .product-container .product-list .product-actions .ant-switch {
    margin-right: 5px; }
  .product-container .product-list .product-actions .anticon:hover {
    color: #0068ff; }

.product-container .product-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .product-container .product-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .product-container .product-list table thead th:nth-child(3),
  .product-container .product-list table thead th:nth-child(4) {
    text-align: center; }
  .product-container .product-list table tbody {
    background: white; }
    .product-container .product-list table tbody tr td {
      font-size: 13px;
      border-bottom-color: #f2f2f2 !important; }
    .product-container .product-list table tbody tr td:nth-child(3),
    .product-container .product-list table tbody tr td:nth-child(4) {
      text-align: center; }

.product-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.product-container .ant-pagination-item-active,
.product-container .ant-pagination-item:hover,
.product-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.product-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.product-container .ant-pagination-item a {
  color: #000000; }

.product-container .ant-pagination-item-active a,
.product-container .ant-pagination-item:hover a,
.product-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.product-container .campaign-empty-state {
  background-image: url("https://d2t1ofb0zlav1n.cloudfront.net/global/images/campaign-state.svg");
  width: 1090px;
  background-repeat: no-repeat;
  margin: auto;
  height: 391px;
  padding: 61px 83px; }
  .product-container .campaign-empty-state h4 {
    font-size: 24px;
    font-weight: 600;
    color: #242425; }
  .product-container .campaign-empty-state p {
    font-size: 14px;
    font-weight: 400;
    color: #242425; }
  .product-container .campaign-empty-state .green-button {
    background: #00db6e;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }
  .product-container .campaign-empty-state .red-button {
    background: #fa5748;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }

.product-addons-modal .ant-modal-body {
  padding: 0 0 15px 0; }
  .product-addons-modal .ant-modal-body table th {
    background: #16273b;
    color: white;
    border-radius: 0 !important; }
  .product-addons-modal .ant-modal-body .ant-btn {
    width: 200px;
    display: block;
    margin: 15px auto 0 auto; }

.product-status-change-confirm-modal .ant-modal-confirm-content {
  margin: auto; }

.product-status-change-confirm-modal .anticon {
  font-size: 50px; }

.product-status-change-confirm-modal .heading {
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0; }

.product-status-change-confirm-modal .ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns * {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0 5px; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns .ant-btn-primary {
    background: #37c627;
    border-color: #37c627; }

.product-card .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .product-card .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .product-card .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.product-card h4 {
  font-size: 24px;
  color: #fa5748;
  margin-bottom: 8px; }

.product-card .form-item-group {
  margin-bottom: 15px; }
  .product-card .form-item-group label {
    display: block;
    text-align: left; }

.product-card .form-item-group.has-error label {
  color: red; }

.product-card .form-item-group.has-error .error-label {
  color: red; }

.product-card .form-item-group.has-error .ant-input {
  border-color: red; }

.product-card .add-addon-link {
  display: block;
  text-align: center;
  margin: auto;
  color: #fa5748;
  font-weight: 500;
  font-size: 18px; }

.product-card .addon {
  display: -webkit-flex;
  display: flex; }
  .product-card .addon * {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0 5px; }

.product-card .ql-editor {
  min-height: 400px; }

.product-card .ant-form-item-label {
  line-height: 27px;
  font-size: 16px;
  color: black;
  margin-bottom: 8px; }

.product-card .ant-input {
  background: #efefef;
  border: 1px solid transparent;
  border-radius: 2px; }

.product-card .footer-button {
  width: 100%;
  background: #1bca00;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  margin-top: 20px; }

.product-card .ant-select-selection {
  background-color: #efefef;
  border-radius: 2px;
  border: none; }

.product-card .main-product-container .product-section {
  margin-top: 16px;
  font-size: 16px;
  color: black;
  margin-bottom: 8px !important;
  text-align: left; }

.product-container .category-tag {
  background: #e3fcff;
  padding: 8px 12px; }

.product-container .product-list .product-details {
  display: -webkit-flex;
  display: flex; }
  .product-container .product-list .product-details img {
    width: 120px;
    border: 1px solid black; }
  .product-container .product-list .product-details .product-info {
    margin-left: 12px; }
    .product-container .product-list .product-details .product-info h4 {
      color: #23202c;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 8px; }
    .product-container .product-list .product-details .product-info p {
      color: #000000a6;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 8px; }

.product-container .product-list .product-description {
  background: #f2f2f2;
  padding: 10px; }
  .product-container .product-list .product-description p {
    margin-bottom: 0px;
    color: #4b4b4b;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px; }

.product-container .product-list .product-cost {
  margin-bottom: 0px;
  color: #23202c;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px; }

.product-container .product-list .product-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: black;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center; }
  .product-container .product-list .product-actions .ant-switch-checked {
    background-color: #36c626; }
  .product-container .product-list .product-actions .anticon {
    font-size: 36px;
    color: #5c5c5c;
    margin-left: 5px; }
  .product-container .product-list .product-actions .ant-switch {
    margin-right: 5px; }
  .product-container .product-list .product-actions .anticon:hover {
    color: #0068ff; }

.product-container .product-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .product-container .product-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .product-container .product-list table thead th:nth-child(3),
  .product-container .product-list table thead th:nth-child(4) {
    text-align: center; }
  .product-container .product-list table tbody {
    background: white; }
    .product-container .product-list table tbody tr td {
      font-size: 13px;
      border-bottom-color: #f2f2f2 !important; }
    .product-container .product-list table tbody tr td:nth-child(3),
    .product-container .product-list table tbody tr td:nth-child(4) {
      text-align: center; }

.product-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.product-container .ant-pagination-item-active,
.product-container .ant-pagination-item:hover,
.product-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.product-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.product-container .ant-pagination-item a {
  color: #000000; }

.product-container .ant-pagination-item-active a,
.product-container .ant-pagination-item:hover a,
.product-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.product-container .campaign-empty-state {
  background-image: url("https://d2t1ofb0zlav1n.cloudfront.net/global/images/campaign-state.svg");
  width: 1090px;
  background-repeat: no-repeat;
  margin: auto;
  height: 391px;
  padding: 61px 83px; }
  .product-container .campaign-empty-state h4 {
    font-size: 24px;
    font-weight: 600;
    color: #242425; }
  .product-container .campaign-empty-state p {
    font-size: 14px;
    font-weight: 400;
    color: #242425; }
  .product-container .campaign-empty-state .green-button {
    background: #00db6e;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }
  .product-container .campaign-empty-state .red-button {
    background: #fa5748;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }

.product-addons-modal .ant-modal-body {
  padding: 0 0 15px 0; }
  .product-addons-modal .ant-modal-body table th {
    background: #16273b;
    color: white;
    border-radius: 0 !important; }
  .product-addons-modal .ant-modal-body .ant-btn {
    width: 200px;
    display: block;
    margin: 15px auto 0 auto; }

.product-status-change-confirm-modal .ant-modal-confirm-content {
  margin: auto; }

.product-status-change-confirm-modal .anticon {
  font-size: 50px; }

.product-status-change-confirm-modal .heading {
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0; }

.product-status-change-confirm-modal .ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns * {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0 5px; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns .ant-btn-primary {
    background: #37c627;
    border-color: #37c627; }

.account-settings {
  padding: 1.5rem 3rem; }
  .account-settings p {
    margin: 0; }
  .account-settings .color-picker-container {
    position: absolute;
    bottom: 100%; }
    .account-settings .color-picker-container .chrome-picker {
      position: absolute;
      bottom: -100%; }
  .account-settings label {
    color: #151515;
    font-size: 1.14rem;
    margin-bottom: 0.5rem;
    font-weight: 400; }
  .account-settings .upload-actions {
    margin-top: 0.71rem;
    display: -webkit-flex;
    display: flex; }
    .account-settings .upload-actions .upload-action {
      width: 7.14rem;
      height: 7.14rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer; }
      .account-settings .upload-actions .upload-action .anticon {
        font-size: 2.25rem; }
  .account-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .account-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .account-settings .heading .ant-btn {
      background: #36c626;
      border-radius: 4px;
      border-color: #36c626 !important;
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      min-height: 2.5rem; }
  .account-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .account-settings .input-container {
    margin-bottom: 1rem; }
    .account-settings .input-container .label {
      color: #151515;
      font-size: 1.14rem;
      margin-bottom: 0.5rem;
      font-weight: 400; }
    .account-settings .input-container .ant-input {
      border: 1px solid #979797;
      border-radius: 4px;
      font-size: 1rem;
      padding: 1rem; }
    .account-settings .input-container .ant-input-disabled {
      background: #f1f0f5; }
    .account-settings .input-container .ant-select-selection:hover {
      border: 1px solid #979797; }
    .account-settings .input-container .switch-text {
      font-size: 1rem;
      font-weight: 400;
      color: #8c8c8c; }
      .account-settings .input-container .switch-text .ant-switch {
        vertical-align: baseline;
        margin-left: 0.5rem; }
      .account-settings .input-container .switch-text .ant-switch-checked {
        background: #36c626; }
  .account-settings .col-1 .input-container {
    margin-bottom: 2rem; }
  .account-settings .domain_name_container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1rem; }
    .account-settings .domain_name_container .ant-input {
      -webkit-flex: 0.9 1;
              flex: 0.9 1; }
    .account-settings .domain_name_container .anticon {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      cursor: pointer; }
      .account-settings .domain_name_container .anticon svg {
        width: 1.8rem;
        height: 1.8rem; }
  .account-settings .add-domain-btn {
    background: #ff5858;
    border-color: #ff5858;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 7px;
    height: 2.5rem; }
    .account-settings .add-domain-btn .anticon + span {
      margin-left: 0.25rem; }
  .account-settings .add-domain-btn:hover,
  .account-settings .add-domain-btn:active,
  .account-settings .add-domain-btn:focus,
  .account-settings .add-domain-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.account-settings {
  padding: 1.5rem 3rem; }
  .account-settings .error-label {
    color: red;
    font-weight: 500; }
  .account-settings p {
    margin: 0; }
  .account-settings .color-picker-container {
    position: absolute;
    bottom: 100%; }
    .account-settings .color-picker-container .chrome-picker {
      position: absolute;
      bottom: -100%; }
  .account-settings label {
    color: #151515;
    font-size: 1.14rem;
    margin-bottom: 0.5rem;
    font-weight: 400; }
  .account-settings .upload-actions {
    margin-top: 0.71rem;
    display: -webkit-flex;
    display: flex; }
    .account-settings .upload-actions .upload-action {
      width: 7.14rem;
      height: 7.14rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer; }
      .account-settings .upload-actions .upload-action .anticon {
        font-size: 2.25rem; }
  .account-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .account-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .account-settings .heading .ant-btn {
      background: #36c626;
      border-radius: 4px;
      border-color: #36c626 !important;
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      min-height: 2.5rem; }
  .account-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .account-settings .input-container {
    margin-bottom: 1rem; }
    .account-settings .input-container .label {
      color: #151515;
      font-size: 1.14rem;
      margin-bottom: 0.5rem;
      font-weight: 400; }
    .account-settings .input-container .ant-input {
      border: 1px solid #979797;
      border-radius: 4px;
      font-size: 1rem;
      padding: 1rem; }
    .account-settings .input-container .ant-input-disabled {
      background: #f1f0f5; }
    .account-settings .input-container .ant-select-selection:hover {
      border: 1px solid #979797; }
    .account-settings .input-container .switch-text {
      font-size: 1rem;
      font-weight: 400;
      color: #8c8c8c; }
      .account-settings .input-container .switch-text .ant-switch {
        vertical-align: baseline;
        margin-left: 0.5rem; }
      .account-settings .input-container .switch-text .ant-switch-checked {
        background: #36c626; }
  .account-settings .col-1 .input-container {
    margin-bottom: 2rem; }
  .account-settings .domain_name_container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1rem; }
    .account-settings .domain_name_container .ant-input {
      -webkit-flex: 0.9 1;
              flex: 0.9 1; }
    .account-settings .domain_name_container .anticon {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      cursor: pointer; }
      .account-settings .domain_name_container .anticon svg {
        width: 1.8rem;
        height: 1.8rem; }
  .account-settings .add-domain-btn {
    background: #ff5858;
    border-color: #ff5858;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 7px;
    height: 2.5rem; }
    .account-settings .add-domain-btn .anticon + span {
      margin-left: 0.25rem; }
  .account-settings .add-domain-btn:hover,
  .account-settings .add-domain-btn:active,
  .account-settings .add-domain-btn:focus,
  .account-settings .add-domain-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.account-settings {
  padding: 1.5rem 3rem; }
  .account-settings .error-label {
    color: red;
    font-weight: 500; }
  .account-settings p {
    margin: 0; }
  .account-settings .color-picker-container {
    position: absolute;
    bottom: 100%; }
    .account-settings .color-picker-container .chrome-picker {
      position: absolute;
      bottom: -100%; }
  .account-settings label {
    color: #151515;
    font-size: 1.14rem;
    margin-bottom: 0.5rem;
    font-weight: 400; }
  .account-settings .upload-actions {
    margin-top: 0.71rem;
    display: -webkit-flex;
    display: flex; }
    .account-settings .upload-actions .upload-action {
      width: 7.14rem;
      height: 7.14rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer; }
      .account-settings .upload-actions .upload-action .anticon {
        font-size: 2.25rem; }
  .account-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .account-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .account-settings .heading .ant-btn {
      background: #36c626;
      border-radius: 4px;
      border-color: #36c626 !important;
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      min-height: 2.5rem; }
  .account-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .account-settings .input-container {
    margin-bottom: 1rem; }
    .account-settings .input-container .label {
      color: #151515;
      font-size: 1.14rem;
      margin-bottom: 0.5rem;
      font-weight: 400; }
    .account-settings .input-container .ant-input {
      border: 1px solid #979797;
      border-radius: 4px;
      font-size: 1rem;
      padding: 1rem; }
    .account-settings .input-container .ant-input-disabled {
      background: #f1f0f5; }
    .account-settings .input-container .ant-select-selection:hover {
      border: 1px solid #979797; }
    .account-settings .input-container .switch-text {
      font-size: 1rem;
      font-weight: 400;
      color: #8c8c8c; }
      .account-settings .input-container .switch-text .ant-switch {
        vertical-align: baseline;
        margin-left: 0.5rem; }
      .account-settings .input-container .switch-text .ant-switch-checked {
        background: #36c626; }
  .account-settings .col-1 .input-container {
    margin-bottom: 2rem; }
  .account-settings .domain_name_container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1rem; }
    .account-settings .domain_name_container .ant-input {
      -webkit-flex: 0.9 1;
              flex: 0.9 1; }
    .account-settings .domain_name_container .anticon {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      cursor: pointer; }
      .account-settings .domain_name_container .anticon svg {
        width: 1.8rem;
        height: 1.8rem; }
  .account-settings .add-domain-btn {
    background: #ff5858;
    border-color: #ff5858;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 7px;
    height: 2.5rem; }
    .account-settings .add-domain-btn .anticon + span {
      margin-left: 0.25rem; }
  .account-settings .add-domain-btn:hover,
  .account-settings .add-domain-btn:active,
  .account-settings .add-domain-btn:focus,
  .account-settings .add-domain-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.translationModal {
  width: 650px !important; }
  .translationModal .ant-btn.ant-dropdown-trigger {
    height: 32px !important; }
  .translationModal .form-item-group {
    margin-bottom: 16px; }
  .translationModal p.form-labels {
    display: none; }
  .translationModal label {
    white-space: normal;
    text-align: left;
    line-height: 1.5;
    margin-bottom: 4px; }
  .translationModal .error-label {
    color: red;
    font-weight: medium; }

.product-container .category-tag {
  background: #e3fcff;
  padding: 8px 12px; }

.product-container .product-list .product-details {
  display: -webkit-flex;
  display: flex; }
  .product-container .product-list .product-details img {
    width: 120px;
    border: 1px solid black; }
  .product-container .product-list .product-details .product-info {
    margin-left: 12px; }
    .product-container .product-list .product-details .product-info h4 {
      color: #23202c;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 8px; }
    .product-container .product-list .product-details .product-info p {
      color: #000000a6;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 8px; }

.product-container .product-list .product-description {
  background: #f2f2f2;
  padding: 10px; }
  .product-container .product-list .product-description p {
    margin-bottom: 0px;
    color: #4b4b4b;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px; }

.product-container .product-list .product-cost {
  margin-bottom: 0px;
  color: #23202c;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px; }

.product-container .product-list .product-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: black;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center; }
  .product-container .product-list .product-actions .ant-switch-checked {
    background-color: #36c626; }
  .product-container .product-list .product-actions .anticon {
    font-size: 36px;
    color: #5c5c5c;
    margin-left: 5px; }
  .product-container .product-list .product-actions .ant-switch {
    margin-right: 5px; }
  .product-container .product-list .product-actions .anticon:hover {
    color: #0068ff; }

.product-container .product-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .product-container .product-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .product-container .product-list table thead th:nth-child(3),
  .product-container .product-list table thead th:nth-child(4) {
    text-align: center; }
  .product-container .product-list table tbody {
    background: white; }
    .product-container .product-list table tbody tr td {
      font-size: 13px;
      border-bottom-color: #f2f2f2 !important; }
    .product-container .product-list table tbody tr td:nth-child(3),
    .product-container .product-list table tbody tr td:nth-child(4) {
      text-align: center; }

.product-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.product-container .ant-pagination-item-active,
.product-container .ant-pagination-item:hover,
.product-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.product-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.product-container .ant-pagination-item a {
  color: #000000; }

.product-container .ant-pagination-item-active a,
.product-container .ant-pagination-item:hover a,
.product-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.product-container .campaign-empty-state {
  background-image: url("https://d2t1ofb0zlav1n.cloudfront.net/global/images/campaign-state.svg");
  width: 1090px;
  background-repeat: no-repeat;
  margin: auto;
  height: 391px;
  padding: 61px 83px; }
  .product-container .campaign-empty-state h4 {
    font-size: 24px;
    font-weight: 600;
    color: #242425; }
  .product-container .campaign-empty-state p {
    font-size: 14px;
    font-weight: 400;
    color: #242425; }
  .product-container .campaign-empty-state .green-button {
    background: #00db6e;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }
  .product-container .campaign-empty-state .red-button {
    background: #fa5748;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }

.product-addons-modal .ant-modal-body {
  padding: 0 0 15px 0; }
  .product-addons-modal .ant-modal-body table th {
    background: #16273b;
    color: white;
    border-radius: 0 !important; }
  .product-addons-modal .ant-modal-body .ant-btn {
    width: 200px;
    display: block;
    margin: 15px auto 0 auto; }

.product-status-change-confirm-modal .ant-modal-confirm-content {
  margin: auto; }

.product-status-change-confirm-modal .anticon {
  font-size: 50px; }

.product-status-change-confirm-modal .heading {
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0; }

.product-status-change-confirm-modal .ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns * {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0 5px; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns .ant-btn-primary {
    background: #37c627;
    border-color: #37c627; }

.account-settings {
  padding: 1.5rem 3rem; }
  .account-settings p {
    margin: 0; }
  .account-settings .color-picker-container {
    position: absolute;
    bottom: 100%; }
    .account-settings .color-picker-container .chrome-picker {
      position: absolute;
      bottom: -100%; }
  .account-settings label {
    color: #151515;
    font-size: 1.14rem;
    margin-bottom: 0.5rem;
    font-weight: 400; }
  .account-settings .upload-actions {
    margin-top: 0.71rem;
    display: -webkit-flex;
    display: flex; }
    .account-settings .upload-actions .upload-action {
      width: 7.14rem;
      height: 7.14rem;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 1rem;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer; }
      .account-settings .upload-actions .upload-action .anticon {
        font-size: 2.25rem; }
  .account-settings .heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: baseline;
            align-items: baseline;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .account-settings .heading .text {
      font-size: 1.43rem;
      font-weight: 400;
      color: #151515; }
    .account-settings .heading .ant-btn {
      background: #36c626;
      border-radius: 4px;
      border-color: #36c626 !important;
      font-size: 1rem;
      color: #fff;
      font-weight: 500;
      padding: 0.5rem 1.5rem;
      min-height: 2.5rem; }
  .account-settings .ant-divider {
    background: #979797;
    height: 2px; }
  .account-settings .input-container {
    margin-bottom: 1rem; }
    .account-settings .input-container .label {
      color: #151515;
      font-size: 1.14rem;
      margin-bottom: 0.5rem;
      font-weight: 400; }
    .account-settings .input-container .ant-input {
      border: 1px solid #979797;
      border-radius: 4px;
      font-size: 1rem;
      padding: 1rem; }
    .account-settings .input-container .ant-input-disabled {
      background: #f1f0f5; }
    .account-settings .input-container .ant-select-selection:hover {
      border: 1px solid #979797; }
    .account-settings .input-container .switch-text {
      font-size: 1rem;
      font-weight: 400;
      color: #8c8c8c; }
      .account-settings .input-container .switch-text .ant-switch {
        vertical-align: baseline;
        margin-left: 0.5rem; }
      .account-settings .input-container .switch-text .ant-switch-checked {
        background: #36c626; }
  .account-settings .col-1 .input-container {
    margin-bottom: 2rem; }
  .account-settings .domain_name_container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1rem; }
    .account-settings .domain_name_container .ant-input {
      -webkit-flex: 0.9 1;
              flex: 0.9 1; }
    .account-settings .domain_name_container .anticon {
      -webkit-flex: 0.3 1;
              flex: 0.3 1;
      cursor: pointer; }
      .account-settings .domain_name_container .anticon svg {
        width: 1.8rem;
        height: 1.8rem; }
  .account-settings .add-domain-btn {
    background: #ff5858;
    border-color: #ff5858;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 7px;
    height: 2.5rem; }
    .account-settings .add-domain-btn .anticon + span {
      margin-left: 0.25rem; }
  .account-settings .add-domain-btn:hover,
  .account-settings .add-domain-btn:active,
  .account-settings .add-domain-btn:focus,
  .account-settings .add-domain-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.library .tenor-logo {
  width: 200px;
  margin: auto; }
  .library .tenor-logo svg {
    width: 100%; }
  .library .tenor-logo svg * {
    stroke: black;
    fill: black; }

.library .heading {
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 1.5rem;
  font-size: 1.28rem;
  font-weight: 300; }

.library .ant-card-cover {
  height: 10rem;
  overflow: hidden; }

.library .selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.3); }

.library .media-meta p {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.75rem; }

.library .media-meta .ant-checkbox-inner {
  width: 1.5rem;
  height: 1.5rem; }

.library .media-meta .ant-checkbox-inner:after {
  width: 0.48rem;
  height: 1.07rem;
  left: 4.571429px;
  top: 9.142857px; }

.library .media-meta .ant-checkbox-wrapper {
  position: absolute;
  bottom: -1px;
  right: 0; }

.product-container .product-list .product-details {
  display: -webkit-flex;
  display: flex; }
  .product-container .product-list .product-details img {
    width: 120px;
    height: 120px;
    border: 1px solid black; }
  .product-container .product-list .product-details .product-info {
    margin-left: 12px; }
    .product-container .product-list .product-details .product-info h4 {
      color: #23202c;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 8px; }
    .product-container .product-list .product-details .product-info p {
      color: #000000a6;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 8px; }
    .product-container .product-list .product-details .product-info .product-category {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .product-container .product-list .product-details .product-info .product-category p {
        color: #4fb958;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 0px;
        background: #d5ffd8;
        padding: 8px;
        margin-right: 6px;
        border-radius: 2px; }
      .product-container .product-list .product-details .product-info .product-category .ant-select-focused .ant-select-selection,
      .product-container .product-list .product-details .product-info .product-category .ant-select-selection:focus,
      .product-container .product-list .product-details .product-info .product-category .ant-select-selection:active {
        border-color: none;
        outline: 0;
        box-shadow: unset; }
      .product-container .product-list .product-details .product-info .product-category .ant-select .ant-select-selection--single {
        height: 36px;
        color: #4fb958;
        font-size: 12px;
        font-weight: 500;
        border: none;
        background: #d5ffd8;
        padding: 8px 4px;
        margin-right: 6px;
        border-radius: 2px; }
        .product-container .product-list .product-details .product-info .product-category .ant-select .ant-select-selection--single .ant-select-selection__rendered {
          line-height: 20px;
          margin-left: 0px; }
          .product-container .product-list .product-details .product-info .product-category .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
            padding-right: 8px; }
          .product-container .product-list .product-details .product-info .product-category .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-arrow .anticon {
            color: #4fb958; }

.product-container .product-list .product-description {
  background: #f2f2f2;
  padding: 10px; }
  .product-container .product-list .product-description p {
    margin-bottom: 0px;
    color: #4b4b4b;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px; }

.product-container .product-list .product-cost {
  margin-bottom: 0px;
  color: #23202c;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px; }

.product-container .product-list .product-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: black;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center; }
  .product-container .product-list .product-actions .ant-switch-checked {
    background-color: #36c626; }
  .product-container .product-list .product-actions .anticon {
    font-size: 36px;
    color: #5c5c5c;
    margin: 0 5px; }
  .product-container .product-list .product-actions .ant-switch {
    margin-right: 5px; }
  .product-container .product-list .product-actions .anticon:hover {
    color: #0068ff; }

.product-container .product-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .product-container .product-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .product-container .product-list table thead th:nth-child(3),
  .product-container .product-list table thead th:nth-child(4) {
    text-align: center; }
  .product-container .product-list table tbody {
    background: white; }
    .product-container .product-list table tbody tr td {
      font-size: 13px;
      border-bottom-color: #f2f2f2 !important; }
    .product-container .product-list table tbody tr td:nth-child(3),
    .product-container .product-list table tbody tr td:nth-child(4) {
      text-align: center; }

.product-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.product-container .ant-pagination-item-active,
.product-container .ant-pagination-item:hover,
.product-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.product-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.product-container .ant-pagination-item a {
  color: #000000; }

.product-container .ant-pagination-item-active a,
.product-container .ant-pagination-item:hover a,
.product-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.product-container .campaign-empty-state {
  background-image: url("https://d2t1ofb0zlav1n.cloudfront.net/global/images/campaign-state.svg");
  width: 1090px;
  background-repeat: no-repeat;
  margin: auto;
  height: 391px;
  padding: 61px 83px; }
  .product-container .campaign-empty-state h4 {
    font-size: 24px;
    font-weight: 600;
    color: #242425; }
  .product-container .campaign-empty-state p {
    font-size: 14px;
    font-weight: 400;
    color: #242425; }
  .product-container .campaign-empty-state .green-button {
    background: #00db6e;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }
  .product-container .campaign-empty-state .red-button {
    background: #fa5748;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }

.product-addons-modal .ant-modal-body {
  padding: 0 0 15px 0; }
  .product-addons-modal .ant-modal-body table th {
    background: #16273b;
    color: white;
    border-radius: 0 !important; }
  .product-addons-modal .ant-modal-body .ant-btn {
    width: 200px;
    display: block;
    margin: 15px auto 0 auto; }

.product-status-change-confirm-modal .ant-modal-confirm-content {
  margin: auto; }

.product-status-change-confirm-modal .anticon {
  font-size: 50px; }

.product-status-change-confirm-modal .heading {
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0; }

.product-status-change-confirm-modal .ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns * {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0 5px; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns .ant-btn-primary {
    background: #37c627;
    border-color: #37c627; }

.categories-container .category-list .category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .category-details img {
    width: 40px;
    border: 1px solid black; }
  .categories-container .category-list .category-details p {
    color: #676666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list .product-in-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .product-in-category p {
    margin-bottom: 0px; }

.categories-container .category-list .ant-btn {
  background: #20aae3;
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  width: 171px;
  margin-left: 29px; }

.categories-container .category-list .category-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .categories-container .category-list .category-actions .ant-switch-checked {
    background-color: #36c626; }
  .categories-container .category-list .category-actions p {
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .categories-container .category-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .categories-container .category-list table thead th:nth-child(3),
  .categories-container .category-list table thead th:nth-child(4) {
    text-align: center; }
  .categories-container .category-list table tbody {
    background: white; }
    .categories-container .category-list table tbody tr td {
      font-size: 14px;
      color: #676666;
      border-bottom-color: #f2f2f2 !important; }
    .categories-container .category-list table tbody tr td:nth-child(3),
    .categories-container .category-list table tbody tr td:nth-child(4) {
      text-align: center; }

.categories-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.categories-container .ant-pagination-item-active,
.categories-container .ant-pagination-item:hover,
.categories-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.categories-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.categories-container .ant-pagination-item a {
  color: #000000; }

.categories-container .ant-pagination-item-active a,
.categories-container .ant-pagination-item:hover a,
.categories-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.announcementModal .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .announcementModal .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .announcementModal .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.announcementModal .ant-modal-header {
  padding: 1.5rem 1.5rem 0px;
  border-bottom: none; }
  .announcementModal .ant-modal-header .ant-modal-title {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: black; }

.announcementModal .form-item-group {
  margin-bottom: 15px; }
  .announcementModal .form-item-group label {
    display: block;
    text-align: left; }

.announcementModal .form-item-group.has-error label {
  color: red; }

.announcementModal .form-item-group.has-error .error-label {
  color: red; }

.announcementModal .form-item-group.has-error .ant-input {
  border-color: red; }

.announcementModal .ant-form-item-label {
  line-height: 27px;
  font-size: 14px;
  color: #242425; }

.announcementModal .ant-modal-footer {
  padding: 0 1.5rem 1.5rem;
  border-top: none;
  text-align: center; }
  .announcementModal .ant-modal-footer .ant-btn {
    font-size: 0.75rem;
    font-weight: 500;
    width: 9rem;
    box-shadow: none; }

.product-card .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .product-card .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .product-card .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.product-card h4 {
  font-size: 24px;
  color: #fa5748;
  margin-bottom: 8px; }

.product-card .form-item-group {
  margin-bottom: 15px; }
  .product-card .form-item-group label {
    display: block;
    text-align: left; }

.product-card .form-item-group.has-error label {
  color: red; }

.product-card .form-item-group.has-error .error-label {
  color: red; }

.product-card .form-item-group.has-error .ant-input {
  border-color: red; }

.product-card .add-addon-link {
  display: block;
  text-align: center;
  margin: auto;
  color: #fa5748;
  font-weight: 500;
  font-size: 18px; }

.product-card .addon {
  display: -webkit-flex;
  display: flex; }
  .product-card .addon * {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0 5px; }

.product-card .ql-editor {
  min-height: 400px; }

.product-card .ant-form-item-label {
  line-height: 27px;
  font-size: 16px;
  color: black;
  margin-bottom: 8px; }

.product-card .ant-input {
  background: #efefef;
  border: 1px solid transparent;
  border-radius: 2px; }

.product-card .footer-button {
  width: 100%;
  background: #1bca00;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  margin-top: 20px; }

.product-card .ant-select-selection {
  background-color: #efefef;
  border-radius: 2px;
  border: none; }

.product-card .main-product-container .product-section {
  margin-top: 16px;
  font-size: 16px;
  color: black;
  margin-bottom: 8px !important;
  text-align: left; }

.product-container .category-tag {
  background: #e3fcff;
  padding: 8px 12px;
  margin: 5px;
  display: inline-block; }

.product-container .product-list .product-details {
  display: -webkit-flex;
  display: flex; }
  .product-container .product-list .product-details img {
    width: 120px;
    height: 120px;
    border: 1px solid black; }
  .product-container .product-list .product-details .product-info {
    margin-left: 12px; }
    .product-container .product-list .product-details .product-info h4 {
      color: #23202c;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 8px; }
    .product-container .product-list .product-details .product-info p {
      color: #000000a6;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 8px; }

.product-container .product-list .product-description {
  background: #f2f2f2;
  padding: 10px; }
  .product-container .product-list .product-description p {
    margin-bottom: 0px;
    color: #4b4b4b;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px; }

.product-container .product-list .product-cost {
  margin-bottom: 0px;
  color: #23202c;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px; }

.product-container .product-list .product-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: black;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center; }
  .product-container .product-list .product-actions .ant-switch-checked {
    background-color: #36c626; }
  .product-container .product-list .product-actions .anticon {
    font-size: 36px;
    color: #5c5c5c;
    margin-left: 5px; }
  .product-container .product-list .product-actions .ant-switch {
    margin-right: 5px; }
  .product-container .product-list .product-actions .anticon:hover {
    color: #0068ff; }

.product-container .product-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .product-container .product-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .product-container .product-list table thead th:nth-child(3),
  .product-container .product-list table thead th:nth-child(4) {
    text-align: center; }
  .product-container .product-list table tbody {
    background: white; }
    .product-container .product-list table tbody tr td {
      font-size: 13px;
      border-bottom-color: #f2f2f2 !important; }
    .product-container .product-list table tbody tr td:nth-child(3),
    .product-container .product-list table tbody tr td:nth-child(4) {
      text-align: center; }

.product-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.product-container .ant-pagination-item-active,
.product-container .ant-pagination-item:hover,
.product-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.product-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.product-container .ant-pagination-item a {
  color: #000000; }

.product-container .ant-pagination-item-active a,
.product-container .ant-pagination-item:hover a,
.product-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.product-container .campaign-empty-state {
  background-image: url("https://d2t1ofb0zlav1n.cloudfront.net/global/images/campaign-state.svg");
  width: 1090px;
  background-repeat: no-repeat;
  margin: auto;
  height: 391px;
  padding: 61px 83px; }
  .product-container .campaign-empty-state h4 {
    font-size: 24px;
    font-weight: 600;
    color: #242425; }
  .product-container .campaign-empty-state p {
    font-size: 14px;
    font-weight: 400;
    color: #242425; }
  .product-container .campaign-empty-state .green-button {
    background: #00db6e;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }
  .product-container .campaign-empty-state .red-button {
    background: #fa5748;
    color: white;
    font-size: 18px;
    border: none;
    box-shadow: unset; }

.product-addons-modal .ant-modal-body {
  padding: 0 0 15px 0; }
  .product-addons-modal .ant-modal-body table th {
    background: #16273b;
    color: white;
    border-radius: 0 !important; }
  .product-addons-modal .ant-modal-body .ant-btn {
    width: 200px;
    display: block;
    margin: 15px auto 0 auto; }

.product-status-change-confirm-modal .ant-modal-confirm-content {
  margin: auto; }

.product-status-change-confirm-modal .anticon {
  font-size: 50px; }

.product-status-change-confirm-modal .heading {
  font-size: 25px;
  font-weight: bold;
  margin: 10px 0; }

.product-status-change-confirm-modal .ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns * {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0 5px; }
  .product-status-change-confirm-modal .ant-modal-confirm-btns .ant-btn-primary {
    background: #37c627;
    border-color: #37c627; }

.product-card .category-select .ant-select-selection__choice {
  display: none; }

.product-card .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .product-card .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .product-card .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.product-card h4 {
  font-size: 24px;
  color: #fa5748;
  margin-bottom: 8px; }

.product-card .form-item-group {
  margin-bottom: 15px; }
  .product-card .form-item-group label {
    display: block;
    text-align: left; }

.product-card .form-item-group.has-error label {
  color: red; }

.product-card .form-item-group.has-error .error-label {
  color: red; }

.product-card .form-item-group.has-error .ant-input {
  border-color: red; }

.product-card .add-addon-link {
  display: block;
  text-align: center;
  margin: auto;
  color: #fa5748;
  font-weight: 500;
  font-size: 18px; }

.product-card .addon {
  display: -webkit-flex;
  display: flex; }
  .product-card .addon * {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0 5px; }

.product-card .ql-editor {
  min-height: 400px; }

.product-card .ant-form-item-label {
  line-height: 27px;
  font-size: 16px;
  color: black;
  margin-bottom: 8px; }

.product-card .ant-input {
  background: #efefef;
  border: 1px solid transparent;
  border-radius: 2px; }

.product-card .footer-button {
  width: 100%;
  background: #1bca00;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  margin-top: 20px; }

.product-card .ant-select-selection {
  background-color: #efefef;
  border-radius: 2px;
  border: none; }

.product-card .main-product-container .product-section {
  margin-top: 16px;
  font-size: 16px;
  color: black;
  margin-bottom: 8px !important;
  text-align: left; }

.categories-container .category-list .category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .category-details img {
    width: 40px;
    border: 1px solid black; }
  .categories-container .category-list .category-details p {
    color: #676666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list .product-in-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .product-in-category p {
    margin-bottom: 0px; }

.categories-container .category-list .ant-btn {
  background: #20aae3;
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  width: 171px;
  margin-left: 29px; }

.categories-container .category-list .category-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .categories-container .category-list .category-actions .ant-switch-checked {
    background-color: #36c626; }
  .categories-container .category-list .category-actions p {
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .categories-container .category-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .categories-container .category-list table thead th:nth-child(3),
  .categories-container .category-list table thead th:nth-child(4) {
    text-align: center; }
  .categories-container .category-list table tbody {
    background: white; }
    .categories-container .category-list table tbody tr td {
      font-size: 14px;
      color: #676666;
      border-bottom-color: #f2f2f2 !important; }
    .categories-container .category-list table tbody tr td:nth-child(3),
    .categories-container .category-list table tbody tr td:nth-child(4) {
      text-align: center; }

.categories-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.categories-container .ant-pagination-item-active,
.categories-container .ant-pagination-item:hover,
.categories-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.categories-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.categories-container .ant-pagination-item a {
  color: #000000; }

.categories-container .ant-pagination-item-active a,
.categories-container .ant-pagination-item:hover a,
.categories-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.categoryModal .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .categoryModal .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .categoryModal .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.categoryModal .products-select .ant-select-selection__choice {
  display: none; }

.categoryModal .ant-modal-header {
  padding: 1.5rem 1.5rem 0px;
  border-bottom: none; }
  .categoryModal .ant-modal-header .ant-modal-title {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: black; }

.categoryModal .form-item-group {
  margin-bottom: 15px; }
  .categoryModal .form-item-group label {
    display: block;
    text-align: left; }

.categoryModal .form-item-group.has-error label {
  color: red; }

.categoryModal .form-item-group.has-error .error-label {
  color: red; }

.categoryModal .form-item-group.has-error .ant-input {
  border-color: red; }

.categoryModal .ant-form-item-label {
  line-height: 27px;
  font-size: 14px;
  color: #242425; }

.categoryModal .ant-modal-footer {
  padding: 0 1.5rem 1.5rem;
  border-top: none;
  text-align: center; }
  .categoryModal .ant-modal-footer .ant-btn {
    font-size: 0.75rem;
    font-weight: 500;
    width: 9rem;
    box-shadow: none; }

.categories-container .category-list .category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .category-details img {
    width: 40px;
    border: 1px solid black; }
  .categories-container .category-list .category-details p {
    color: #676666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list .product-in-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .product-in-category p {
    margin-bottom: 0px; }

.categories-container .category-list .ant-btn {
  background: #20aae3;
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  width: 171px;
  margin-left: 29px; }

.categories-container .category-list .category-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .categories-container .category-list .category-actions .ant-switch-checked {
    background-color: #36c626; }
  .categories-container .category-list .category-actions p {
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .categories-container .category-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .categories-container .category-list table thead th:nth-child(3),
  .categories-container .category-list table thead th:nth-child(4) {
    text-align: center; }
  .categories-container .category-list table tbody {
    background: white; }
    .categories-container .category-list table tbody tr td {
      font-size: 14px;
      color: #676666;
      border-bottom-color: #f2f2f2 !important; }
    .categories-container .category-list table tbody tr td:nth-child(3),
    .categories-container .category-list table tbody tr td:nth-child(4) {
      text-align: center; }

.categories-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.categories-container .ant-pagination-item-active,
.categories-container .ant-pagination-item:hover,
.categories-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.categories-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.categories-container .ant-pagination-item a {
  color: #000000; }

.categories-container .ant-pagination-item-active a,
.categories-container .ant-pagination-item:hover a,
.categories-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.categoryModal .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .categoryModal .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .categoryModal .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.categoryModal .products-select .ant-select-selection__choice {
  display: none; }

.categoryModal .ant-modal-header {
  padding: 1.5rem 1.5rem 0px;
  border-bottom: none; }
  .categoryModal .ant-modal-header .ant-modal-title {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: black; }

.categoryModal .form-item-group {
  margin-bottom: 15px; }
  .categoryModal .form-item-group label {
    display: block;
    text-align: left; }

.categoryModal .form-item-group.has-error label {
  color: red; }

.categoryModal .form-item-group.has-error .error-label {
  color: red; }

.categoryModal .form-item-group.has-error .ant-input {
  border-color: red; }

.categoryModal .ant-form-item-label {
  line-height: 27px;
  font-size: 14px;
  color: #242425; }

.categoryModal .ant-modal-footer {
  padding: 0 1.5rem 1.5rem;
  border-top: none;
  text-align: center; }
  .categoryModal .ant-modal-footer .ant-btn {
    font-size: 0.75rem;
    font-weight: 500;
    width: 9rem;
    box-shadow: none; }

.categories-container .category-list .category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .category-details img {
    width: 40px;
    border: 1px solid black; }
  .categories-container .category-list .category-details p {
    color: #676666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list .product-in-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .product-in-category p {
    margin-bottom: 0px; }
  .categories-container .category-list .product-in-category .ant-btn {
    background: #20aae3;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    width: 171px;
    margin-left: 29px; }

.categories-container .category-list .category-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .categories-container .category-list .category-actions .ant-switch-checked {
    background-color: #36c626; }
  .categories-container .category-list .category-actions p {
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .categories-container .category-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .categories-container .category-list table thead th:nth-child(3),
  .categories-container .category-list table thead th:nth-child(4) {
    text-align: center; }
  .categories-container .category-list table tbody {
    background: white; }
    .categories-container .category-list table tbody tr td {
      font-size: 14px;
      color: #676666;
      border-bottom-color: #f2f2f2 !important; }
    .categories-container .category-list table tbody tr td:nth-child(3),
    .categories-container .category-list table tbody tr td:nth-child(4) {
      text-align: center; }

.categories-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.categories-container .ant-pagination-item-active,
.categories-container .ant-pagination-item:hover,
.categories-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.categories-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.categories-container .ant-pagination-item a {
  color: #000000; }

.categories-container .ant-pagination-item-active a,
.categories-container .ant-pagination-item:hover a,
.categories-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.categoryModal .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .categoryModal .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .categoryModal .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.categoryModal .ant-modal-header {
  padding: 1.5rem 1.5rem 0px;
  border-bottom: none; }
  .categoryModal .ant-modal-header .ant-modal-title {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: black; }

.categoryModal .form-item-group {
  margin-bottom: 15px; }
  .categoryModal .form-item-group label {
    display: block;
    text-align: left; }

.categoryModal .form-item-group.has-error label {
  color: red; }

.categoryModal .form-item-group.has-error .error-label {
  color: red; }

.categoryModal .form-item-group.has-error .ant-input {
  border-color: red; }

.categoryModal .ant-form-item-label {
  line-height: 27px;
  font-size: 14px;
  color: #242425; }

.categoryModal .ant-modal-footer {
  padding: 0 1.5rem 1.5rem;
  border-top: none;
  text-align: center; }
  .categoryModal .ant-modal-footer .ant-btn {
    font-size: 0.75rem;
    font-weight: 500;
    width: 9rem;
    box-shadow: none; }

.order-card {
  margin-bottom: 32px;
  color: #676666;
  padding-top: 20px; }
  .order-card .order-id-label {
    display: inline;
    background: #21aae3;
    color: white;
    padding: 5px 10px;
    position: absolute;
    left: -8px;
    top: 10px; }
  .order-card .order-id-label:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #21aae3;
    top: -8px;
    left: 0px;
    position: absolute; }
  .order-card .header-item {
    display: -webkit-flex;
    display: flex; }
    .order-card .header-item .label {
      min-width: 91px; }
    .order-card .header-item .value {
      -webkit-flex: 1 1;
              flex: 1 1; }

.order-cancel-confirm {
  text-align: center; }

.categories-container .order-empty-state {
  position: relative;
  overflow: hidden;
  z-index: 1; }

.categories-container .order-empty-state:before {
  z-index: -1;
  content: "";
  width: 372px;
  height: 372px;
  top: 186px;
  left: 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  background: #f8f8ff;
  position: absolute;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg); }

.categories-container .order-empty-state:after {
  z-index: -1;
  content: "";
  width: 372px;
  height: 372px;
  bottom: 186px;
  right: 0;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  background: #f8f8ff;
  position: absolute;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg); }

.categories-container .category-list .category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .category-details img {
    width: 40px;
    border: 1px solid black; }
  .categories-container .category-list .category-details p {
    color: #676666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list .product-in-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .product-in-category p {
    margin-bottom: 0px; }
  .categories-container .category-list .product-in-category .ant-btn {
    background: #20aae3;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    width: 171px;
    margin-left: 29px; }

.categories-container .category-list .category-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .categories-container .category-list .category-actions .ant-switch-checked {
    background-color: #36c626; }
  .categories-container .category-list .category-actions p {
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .categories-container .category-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .categories-container .category-list table thead th:nth-child(3),
  .categories-container .category-list table thead th:nth-child(4) {
    text-align: center; }
  .categories-container .category-list table tbody {
    background: white; }
    .categories-container .category-list table tbody tr td {
      font-size: 14px;
      color: #676666;
      border-bottom-color: #f2f2f2 !important; }
    .categories-container .category-list table tbody tr td:nth-child(3),
    .categories-container .category-list table tbody tr td:nth-child(4) {
      text-align: center; }

.categories-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.categories-container .ant-pagination-item-active,
.categories-container .ant-pagination-item:hover,
.categories-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.categories-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.categories-container .ant-pagination-item a {
  color: #000000; }

.categories-container .ant-pagination-item-active a,
.categories-container .ant-pagination-item:hover a,
.categories-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.categories-container .category-list .category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .category-details img {
    width: 40px;
    border: 1px solid black; }
  .categories-container .category-list .category-details p {
    color: #676666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list .product-in-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .product-in-category p {
    margin-bottom: 0px; }
  .categories-container .category-list .product-in-category .ant-btn {
    background: #20aae3;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    width: 171px;
    margin-left: 29px; }

.categories-container .category-list .category-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .categories-container .category-list .category-actions .ant-switch-checked {
    background-color: #36c626; }
  .categories-container .category-list .category-actions p {
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .categories-container .category-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .categories-container .category-list table thead th:nth-child(3),
  .categories-container .category-list table thead th:nth-child(4) {
    text-align: center; }
  .categories-container .category-list table tbody {
    background: white; }
    .categories-container .category-list table tbody tr td {
      font-size: 14px;
      color: #676666;
      border-bottom-color: #f2f2f2 !important; }
    .categories-container .category-list table tbody tr td:nth-child(3),
    .categories-container .category-list table tbody tr td:nth-child(4) {
      text-align: center; }

.categories-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.categories-container .ant-pagination-item-active,
.categories-container .ant-pagination-item:hover,
.categories-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.categories-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.categories-container .ant-pagination-item a {
  color: #000000; }

.categories-container .ant-pagination-item-active a,
.categories-container .ant-pagination-item:hover a,
.categories-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.customer-details-modal .ant-modal-header {
  padding: 34px 24px 0 24px;
  border-bottom: 0; }
  .customer-details-modal .ant-modal-header h1 {
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin-bottom: 6px; }
  .customer-details-modal .ant-modal-header p {
    font-size: 12px;
    color: #707070; }

.customer-details-modal .field label {
  color: #707070;
  line-height: 22px;
  font-size: 12px; }

.customer-details-modal .field p {
  font-size: 12px;
  color: black;
  background: #eeeeee;
  padding: 10px 8px; }

.customer-details-modal .past-orders {
  max-height: 169px;
  overflow: auto;
  padding: 12px 8px;
  background: #eeeeee; }
  .customer-details-modal .past-orders .order {
    padding-bottom: 16px; }
    .customer-details-modal .past-orders .order .order-date {
      background: #e5e5e5;
      font-size: 8px;
      color: #797979;
      padding: 3px 8px; }
    .customer-details-modal .past-orders .order .order-items .order-item {
      font-size: 12px;
      color: black;
      margin-bottom: 8px; }

.categories-container .category-list .category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .category-details img {
    width: 40px;
    border: 1px solid black; }
  .categories-container .category-list .category-details p {
    color: #676666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list .product-in-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .product-in-category p {
    margin-bottom: 0px; }
  .categories-container .category-list .product-in-category .ant-btn {
    background: #20aae3;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    width: 171px;
    margin-left: 29px; }

.categories-container .category-list .category-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .categories-container .category-list .category-actions .ant-switch-checked {
    background-color: #36c626; }
  .categories-container .category-list .category-actions p {
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .categories-container .category-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .categories-container .category-list table thead th:nth-child(3),
  .categories-container .category-list table thead th:nth-child(4) {
    text-align: center; }
  .categories-container .category-list table tbody {
    background: white; }
    .categories-container .category-list table tbody tr td {
      font-size: 14px;
      color: #676666;
      border-bottom-color: #f2f2f2 !important; }
    .categories-container .category-list table tbody tr td:nth-child(3),
    .categories-container .category-list table tbody tr td:nth-child(4) {
      text-align: center; }

.categories-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.categories-container .ant-pagination-item-active,
.categories-container .ant-pagination-item:hover,
.categories-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.categories-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.categories-container .ant-pagination-item a {
  color: #000000; }

.categories-container .ant-pagination-item-active a,
.categories-container .ant-pagination-item:hover a,
.categories-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.npsModal .ant-modal-header {
  padding: 34px 24px 0 24px;
  border-bottom: 0; }
  .npsModal .ant-modal-header h1 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px; }
  .npsModal .ant-modal-header p {
    color: #707070;
    font-size: 12px;
    margin: 0; }

.npsModal .ant-select-selection-selected-value {
  width: 100%; }
  .npsModal .ant-select-selection-selected-value svg {
    display: block;
    margin-left: auto;
    margin-right: 15px; }

.npsModal .form-item-group {
  margin-bottom: 15px; }
  .npsModal .form-item-group label {
    display: block;
    text-align: left; }

.npsModal .form-item-group.has-error label {
  color: red; }

.npsModal .form-item-group.has-error .error-label {
  color: red; }

.npsModal .form-item-group.has-error .ant-input {
  border-color: red; }

.npsModal .ant-form-item-label {
  line-height: 27px;
  font-size: 14px;
  color: #242425; }

.categories-container .category-list .category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .category-details img {
    width: 40px;
    border: 1px solid black; }
  .categories-container .category-list .category-details p {
    color: #676666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list .product-in-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .product-in-category p {
    margin-bottom: 0px; }

.categories-container .category-list .ant-btn {
  background: #20aae3;
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  width: 171px;
  margin-left: 29px; }

.categories-container .category-list .category-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .categories-container .category-list .category-actions .ant-switch-checked {
    background-color: #36c626; }
  .categories-container .category-list .category-actions p {
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .categories-container .category-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .categories-container .category-list table thead th:nth-child(3),
  .categories-container .category-list table thead th:nth-child(4) {
    text-align: center; }
  .categories-container .category-list table tbody {
    background: white; }
    .categories-container .category-list table tbody tr td {
      font-size: 14px;
      color: #676666;
      border-bottom-color: #f2f2f2 !important; }
    .categories-container .category-list table tbody tr td:nth-child(3),
    .categories-container .category-list table tbody tr td:nth-child(4) {
      text-align: center; }

.categories-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.categories-container .ant-pagination-item-active,
.categories-container .ant-pagination-item:hover,
.categories-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.categories-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.categories-container .ant-pagination-item a {
  color: #000000; }

.categories-container .ant-pagination-item-active a,
.categories-container .ant-pagination-item:hover a,
.categories-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.announcementModal .upload-actions {
  margin-top: 0.71rem;
  display: -webkit-flex;
  display: flex; }
  .announcementModal .upload-actions .upload-action {
    width: 7.14rem;
    height: 7.14rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 1rem;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 0.5rem;
    cursor: pointer; }
    .announcementModal .upload-actions .upload-action .anticon {
      font-size: 2.25rem; }

.announcementModal .ant-modal-header {
  padding: 1.5rem 1.5rem 0px;
  border-bottom: none; }
  .announcementModal .ant-modal-header .ant-modal-title {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: black; }

.announcementModal .form-item-group {
  margin-bottom: 15px; }
  .announcementModal .form-item-group label {
    display: block;
    text-align: left; }

.announcementModal .form-item-group.has-error label {
  color: red; }

.announcementModal .form-item-group.has-error .error-label {
  color: red; }

.announcementModal .form-item-group.has-error .ant-input {
  border-color: red; }

.announcementModal .ant-form-item-label {
  line-height: 27px;
  font-size: 14px;
  color: #242425; }

.announcementModal .ant-modal-footer {
  padding: 0 1.5rem 1.5rem;
  border-top: none;
  text-align: center; }
  .announcementModal .ant-modal-footer .ant-btn {
    font-size: 0.75rem;
    font-weight: 500;
    width: 9rem;
    box-shadow: none; }

.categories-container .category-list .category-details {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .category-details img {
    width: 40px;
    border: 1px solid black; }
  .categories-container .category-list .category-details p {
    color: #676666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list .product-in-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .categories-container .category-list .product-in-category p {
    margin-bottom: 0px; }
  .categories-container .category-list .product-in-category .ant-btn {
    background: #20aae3;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    width: 171px;
    margin-left: 29px; }

.categories-container .category-list .category-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .categories-container .category-list .category-actions .ant-switch-checked {
    background-color: #36c626; }
  .categories-container .category-list .category-actions p {
    margin-bottom: 0px;
    margin-left: 16px; }

.categories-container .category-list table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .categories-container .category-list table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .categories-container .category-list table thead th:nth-child(3),
  .categories-container .category-list table thead th:nth-child(4) {
    text-align: center; }
  .categories-container .category-list table tbody {
    background: white; }
    .categories-container .category-list table tbody tr td {
      font-size: 14px;
      color: #676666;
      border-bottom-color: #f2f2f2 !important; }
    .categories-container .category-list table tbody tr td:nth-child(3),
    .categories-container .category-list table tbody tr td:nth-child(4) {
      text-align: center; }

.categories-container .ant-table-pagination.ant-pagination {
  margin: 36px 0px;
  float: unset;
  text-align: center; }

.categories-container .ant-pagination-item-active,
.categories-container .ant-pagination-item:hover,
.categories-container .ant-pagination-item:active {
  background: #1655c0 !important;
  border-color: #1655c0 !important;
  outline: none !important; }

.categories-container .ant-pagination-item {
  background: white;
  border-color: #707070;
  outline: none !important; }

.categories-container .ant-pagination-item a {
  color: #000000; }

.categories-container .ant-pagination-item-active a,
.categories-container .ant-pagination-item:hover a,
.categories-container .ant-pagination-item:active a {
  color: #ffffff !important; }

.qr-code-gen-iframe {
  width: 100%;
  min-height: 600px;
  border: none; }

.bonus-page table {
  width: 100%; }
  .bonus-page table td {
    vertical-align: top;
    padding: 8px 0; }
  .bonus-page table th:nth-child(2), .bonus-page table td:nth-child(2) {
    width: calc(100% - 400px);
    padding-left: 15px;
    padding-right: 15px; }

.bonus-page table {
  width: 100%; }
  .bonus-page table td {
    vertical-align: top;
    padding: 8px 0; }
  .bonus-page table th:nth-child(2), .bonus-page table td:nth-child(2) {
    width: calc(100% - 400px);
    padding-left: 15px;
    padding-right: 15px; }

