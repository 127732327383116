.categories-container {
  .order-empty-state {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .order-empty-state:before {
    z-index: -1;
    content: "";
    width: 372px;
    height: 372px;
    top: 186px;
    left: 0;
    transform-origin: 0 0;
    background: #f8f8ff;
    position: absolute;
    transform: rotate(-135deg);
  }
  .order-empty-state:after {
    z-index: -1;
    content: "";
    width: 372px;
    height: 372px;
    bottom: 186px;
    right: 0;
    transform-origin: 100% 100%;
    background: #f8f8ff;
    position: absolute;
    transform: rotate(-135deg);
  }
  .category-list {
    .category-details {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        border: 1px solid black;
      }

      p {
        color: #676666;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 0px;
        margin-left: 16px;
      }
    }
    .product-in-category {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0px;
      }
      .ant-btn {
        background: #20aae3;
        color: white;
        border: none;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 600;
        width: 171px;
        margin-left: 29px;
      }
    }
    .category-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-switch-checked {
        background-color: #36c626;
      }
      p {
        margin-bottom: 0px;
        margin-left: 16px;
      }
    }

    table {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      thead {
        tr {
          th {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.3px;
            background: #16273b !important;
            border-radius: 0 !important;
            color: white !important;
          }
        }
        th:nth-child(3),
        th:nth-child(4) {
          text-align: center;
        }
      }
      tbody {
        background: white;
        tr {
          td {
            font-size: 14px;
            color: #676666;
            border-bottom-color: #f2f2f2 !important;
          }
          td:nth-child(3),
          td:nth-child(4) {
            text-align: center;
          }
        }
      }
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 36px 0px;
    float: unset;
    text-align: center;
  }
  .ant-pagination-item-active,
  .ant-pagination-item:hover,
  .ant-pagination-item:active {
    background: #1655c0 !important;
    border-color: #1655c0 !important;
    outline: none !important;
  }
  .ant-pagination-item {
    background: white;
    border-color: #707070;
    outline: none !important;
  }
  .ant-pagination-item a {
    color: #000000;
  }
  .ant-pagination-item-active a,
  .ant-pagination-item:hover a,
  .ant-pagination-item:active a {
    color: #ffffff !important;
  }
}
